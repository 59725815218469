import {
  Component,
  OnInit,
  TemplateRef,
  ChangeDetectorRef,
} from '@angular/core';
import {
  triggerSingleDropdown,
  triggerClickEvent,
} from 'src/app/app.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { addWidget, addBusiness, widgetVariable } from '../registration';
import { UserService } from '../../user.service';
import { ToastrService } from 'ngx-toastr';
import { MapsAPILoader } from '@agm/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
declare let google: any;

@Component({
  selector: 'app-create-widget',
  templateUrl: './create-widget.component.html',
  styleUrls: ['./create-widget.component.scss'],
})
export class CreateWidgetComponent implements OnInit {
  addWidgetModel = new addWidget();
  widgetVariableModel = new widgetVariable();
  addBusinessModel = new addBusiness();
  modalRef: BsModalRef;
  modalRef1: BsModalRef;
  selectedValue = '--Select--';
  businessList = [];
  dropdownSettings = {};
  selectedInterval = '---Select---';
  SelectedState = 'Select State';
  SelectedCity = 'Select City';
  staffList = [];
  selectedList = [];
  serverBusy = false;
  stepNo = 0;
  showPhoneNumber = true;
  private unsubscribeAll: Subject<any>;
  constructor(
    private changeDetector: ChangeDetectorRef,
    private modalService: BsModalService,
    private mapsAPILoader: MapsAPILoader,
    private toastr: ToastrService,
    private userService: UserService
  ) {
    this.unsubscribeAll = new Subject();
    this.addWidgetModel = new addWidget();
    this.getAllStaffList();
    // this.getBusiness()
    this.getState();
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      defaultOpen: false,
      allowSearchFilter: true,
    };
    this.userService.preStepChangeData
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((data) => {
        if (data) {
          this.stepNo = data;
        }
      });
    if (window.localStorage['addWidget']) {
      this.addWidgetModel = JSON.parse(window.localStorage['addWidget']);
      if (this.addWidgetModel.business_id.length > 0) {
        var stringToAarry = this.addWidgetModel.business_id.split(',');
        let val = [];
        stringToAarry.forEach((v) => {
          let find = this.businessList.find((f) => f.id == v);
          if (find) {
            let obj = {
              id: find.id,
              name: find.name,
            };
            val.push(obj);
          }
        });
        this.selectedList = val;
      }
    }
  }

  getState() {
    this.userService.getStateList().subscribe(
      (data) => {
        this.widgetVariableModel.stateList = data.result;
      },
      (error) => {
        this.toastr.error(error);
      }
    );
  }

  getBusiness() {
    let inputParam = {
      keyword: '',
    };
    this.userService.getAllBusiness(inputParam).subscribe(
      (res) => {
        this.businessList = res['result'];
        if (this.addWidgetModel.business_id.length > 0) {
          var stringToAarry = this.addWidgetModel.business_id.split(',');
          let val = [];
          stringToAarry.forEach((v) => {
            let find = this.businessList.find((f) => f.id == v);
            if (find) {
              let obj = {
                id: find.id,
                name: find.name,
              };
              val.push(obj);
            }
          });
          this.selectedList = val;
        }
      },
      (error) => {
        this.toastr.warning(error);
      }
    );
  }

  getAllStaffList() {
    this.userService.getAllUserList().subscribe(
      (data) => {
        this.staffList = data.result;
        this.serverBusy = false;
      },
      (error) => {
        this.serverBusy = false;
        this.toastr.error(error);
      }
    );
  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  cancel() {
    this.addBusinessModel = new addBusiness();
    this.modalRef.hide();
  }

  tagLoc() {
    this.mapsAPILoader.load().then(() => {
      this.initAutocomplete();
    });
  }

  initAutocomplete() {
    var map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 33.4372894, lng: -111.6847036 },
      zoom: 10,
      mapTypeId: 'roadmap',
    });

    var input = document.getElementById('pac-input');
    let placeData = new google.maps.places.PlacesService(map);
    var searchBox = new google.maps.places.SearchBox(input);

    let t = this;
    this.widgetVariableModel.fullPlaceData = [];

    map.addListener('bounds_changed', function () {
      searchBox.setBounds(map.getBounds());
    });

    let markers = [];

    searchBox.addListener('places_changed', function () {
      var places = searchBox.getPlaces();
      markers.forEach(function (marker) {
        marker.setMap(null);
      });
      var infowindow = new google.maps.InfoWindow();
      var bounds = new google.maps.LatLngBounds();
      let i = 0;

      places.forEach(function (place) {
        if (!place.geometry) {
          return;
        }
        placeData.getDetails(
          { placeId: place.place_id },
          function (data, status) {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              return;
            } else {
              t.widgetVariableModel.fullPlaceData.push(data);
              var icon = {
                url: place.icon,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(25, 25),
              };

              let markerVal = new google.maps.Marker({
                map: map,
                icon: icon,
                title: place.name,
                position: place.geometry.location,
                animation: google.maps.Animation.DROP,
                place_id: place.place_id,
              });

              var content =
                "<div class='map_info_wrapper'><a href=" +
                place.icon +
                "><div class='img_wrapper'><img src=" +
                place.icon +
                '></div>' +
                "<div class='property_content_wrap'>" +
                "<div class='property_title'>" +
                '<span>' +
                place.name +
                '</span>' +
                '</div>' +
                "<div class='property_bed_type'>" +
                '<span>' +
                place.formatted_address +
                '</span>' +
                '</div>';

              google.maps.event.addListener(
                markerVal,
                'click',
                (function (marker, content, i) {
                  return function () {
                    infowindow.setContent(content);
                    infowindow.open(map, marker);
                  };
                })(markerVal, content, i)
              );

              i = i + 1;

              markers.push(markerVal);
              t.widgetVariableModel.markers = markers;

              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }

              map.fitBounds(bounds);
            }
          }
        );
      });
      setTimeout(() => {
        input.focus();
      }, 600);
    });
  }

  loader = false;

  save() {
    this.loader = true;
    this.serverBusy = true;
    this.addWidgetModel.business_id = [];
    let val = [];
    if (this.selectedList.length == 0) {
      val = [0];
    } else {
      this.selectedList.forEach((v) => {
        val.push(v.id);
      });
    }
    this.addWidgetModel.business_id = val.toString();
    this.addWidgetModel.assign_user_phone = this.userService.phoneNumberset(
      this.addWidgetModel.assign_user_phone
    );
    this.userService.saveWidgetSetting(this.addWidgetModel).subscribe(
      (data) => {
        window.localStorage['addWidget'] = JSON.stringify(this.addWidgetModel);
        this.userService.onNextSteoData(5, 'next');
        let token = data['result'].api_token;
        this.userService.onTokenSet(token);
        this.serverBusy = false;
        this.loader = false;
        this.toastr.success(data.message);
      },
      (error) => {
        this.serverBusy = false;
        this.loader = false;
        this.toastr.error(error);
      }
    );
  }

  selectItem(item) {
    this.addWidgetModel['staff_name'] = item.full_name;
    this.addWidgetModel['user_id'] = item.id;
    this.addWidgetModel['assign_user_phone'] = item.phone_number;
    this.showPhoneNumber = false;
    setTimeout(() => {
      this.showPhoneNumber = true;
    }, 5);
  }

  dropdownClicked(dropId: string) {
    triggerSingleDropdown(dropId);
  }

  selectInterVal(interval: string) {
    this.addWidgetModel['review_show_interval'] = interval;
  }

  triggerDropPos(multiSelectId: any) {
    triggerClickEvent(multiSelectId);
  }
  changeState(item) {
    this.SelectedState = item;
  }
  changeCity(item) {
    this.SelectedCity = item;
  }

  openModal(googleReview: TemplateRef<any>) {
    let config = {
      ignoreBackdropClick: true,
      class: 'google-review-modal custom-modal modal-lg',
    };
    this.modalRef = this.modalService.show(
      googleReview,
      Object.assign({}, config)
    );
    this.tagLoc();
  }

  openbusinessCreation(i, item, createBusiness: TemplateRef<any>) {
    this.serverBusy = true;
    var markers = this.widgetVariableModel.markers;
    google.maps.event.trigger(markers[i], 'click');
    this.modalRef.hide();
    this.autoPopulateVal(item, createBusiness);
  }

  autoPopulateVal(item, createBusiness) {
    if (item.address_components) {
      let address = '';
      let state = '';
      let postalCode = '';
      let phoneNumber = '';
      let city = '';
      item.address_components.forEach((v) => {
        if (v.types.find((f) => f == 'street_number')) {
          address = v.long_name;
        } else if (v.types.find((f) => f == 'route')) {
          if (address) {
            address = address + ',' + v.long_name;
          } else {
            address = v.long_name;
          }
        } else if (v.types.find((f) => f == 'locality')) {
          city = v.long_name;
        } else if (v.types.find((f) => f == 'administrative_area_level_1')) {
          state = v.short_name;
        } else if (v.types.find((f) => f == 'postal_code')) {
          postalCode = v.long_name;
        }
      });
      if (item.formatted_phone_number) {
        phoneNumber = item.formatted_phone_number;
      }

      if (item['formatted_address'] && !address) {
        address = item.formatted_address;
      }
      let stateVal = this.widgetVariableModel.stateList.find(
        (v) => v.state_code == state
      );
      if (stateVal) {
        this.getCityByState(stateVal.id, city);
        this.addBusinessModel.state = this.widgetVariableModel.stateList.find(
          (v) => v.state_code == state
        ).id;
        this.addBusinessModel.state_name =
          this.widgetVariableModel.stateList.find(
            (v) => v.state_code == state
          ).state;
        this.addBusinessModel.place_id = item['place_id'];
        this.addBusinessModel.lat = item.geometry.location.lat().toString();
        this.addBusinessModel.lng = item.geometry.location.lng().toString();
        this.addBusinessModel.address = address;
        this.addBusinessModel.zip = postalCode;
        this.addBusinessModel.phone = phoneNumber;
        this.addBusinessModel.name = item.name;
        this.showPhoneNumber = false;
        setTimeout(() => {
          this.showPhoneNumber = true;
        }, 5);
      } else {
        this.toastr.error('Location allowed only for USA');
      }
    }
    this.serverBusy = false;
    let config = {
      ignoreBackdropClick: true,
      class: 'createBusiness custom-modal modal-lg',
    };
    this.modalRef = this.modalService.show(createBusiness, config);
  }

  getCityByState(val, city) {
    this.addBusinessModel.city = '';
    let stateVal = this.widgetVariableModel.stateList.find((v) => v.id == val);
    if (stateVal) {
      this.userService.getCityList(stateVal.state_code).subscribe(
        (data) => {
          this.widgetVariableModel.cityList = data.result;
          if (city) {
            let cityVal = this.widgetVariableModel.cityList.find(
              (v) => v.city == city
            );
            if (cityVal) {
              this.addBusinessModel.city = cityVal.id;
              this.addBusinessModel.city_name = cityVal.city;
            }
          }
        },
        (error) => {
          this.serverBusy = false;
          this.toastr.error(error);
        }
      );
    }
  }

  saveBusiness() {
    this.serverBusy = true;
    this.addBusinessModel.phone = this.userService.phoneNumberset(
      this.addBusinessModel.phone
    );
    this.userService.saveBusinessData(this.addBusinessModel).subscribe(
      (data) => {
        this.modalRef.hide();
        this.addBusinessModel = new addBusiness();
        this.getBusiness();
        this.changeDetector.detectChanges();
      },
      (error) => {
        this.serverBusy = false;
        this.toastr.error(error);
      }
    );
  }

  preStep() {
    this.userService.onNextSteoData(5, 'pre');
  }

  redirectToNext() {
    this.userService.onNextSteoData(5, 'next');
  }
}
