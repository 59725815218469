import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  activeTab = []
  private unsubscribeAll: Subject<any>;
  constructor(private userService: UserService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.activeTab = [{
      "stepName" : "registration",
      "stepNo" : 1,
      "active" : true
    },{
      "stepName" : "pricing",
      "stepNo" : 2,
      "active" : false
    },{
      "stepName" : "search",
      "stepNo" : 3,
      "active" : false
    },{
      "stepName" : "staff",
      "stepNo" : 4,
      "active" : false
    },{
      "stepName" : "widget",
      "stepNo" : 5,
      "active" : false
    },{
      "stepName" : "installation",
      "stepNo" : 6,
      "active" : false
    }]

    this.userService.nextStepChangeData.pipe(takeUntil(this.unsubscribeAll)).subscribe(data => {
      if (data) {
        if(data.type=='next'){
          if(window.localStorage['stepNo']){
            if(data.stepNo>window.localStorage['stepNo']){
              window.localStorage['stepNo'] = data.stepNo;
            }
          } else {
            window.localStorage['stepNo'] = data.stepNo;
          }
          if(data.stepNo==5){
            this.activeTab.forEach(v=>{
              v.active = false
            })
          }
          this.activeTab[data.stepNo].active = true
          setTimeout(() => {
            let stepId = "next_"+data.stepNo
            let getbtn = document.getElementById(stepId)
            if(getbtn){
              getbtn.click()
            }
          }, 200);
        } else if(data.type=='pre'){
          setTimeout(() => {
            if(window.localStorage['stepNo']){
              let stepNo = window.localStorage['stepNo']
              this.userService.onPreStepData(stepNo)
            }
            let stepId = "pre_"+data.stepNo
            let getbtn = document.getElementById(stepId)
            if(getbtn){
              getbtn.click()
            }
          }, 200);
        }
      }
    })
    if(window.localStorage['stepNo']){
      let i = 0
      this.activeTab.forEach(v=>{
        if(i<=window.localStorage['stepNo']){
          v.active = true
        }
        i = i+1
      })
    }
  }
  ngAfterViewInit() {
    let eventSelector = document.querySelectorAll('.steps-indicator li');
    var elementTop = document.getElementsByClassName('large-empty-symbols')[0].clientHeight;
    var windowWidth = window.innerWidth;
    if (windowWidth <= 767) {
      for (let i = 0; i < eventSelector.length; i++) {
        eventSelector[i].addEventListener("click", function () {
          window.scrollTo(0, elementTop);
        });
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }

  enterStep(event){
    if(window.localStorage['stepNo']){
      let stepNo = window.localStorage['stepNo']
      this.userService.onPreStepData(stepNo)
    }
  }
}
