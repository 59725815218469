<form name="basicInfoForm" #basicInfoForm="ngForm">
  <div class="pt-4 pb-4">
    <h2 class="regHeading">Create An Account</h2>
    <p class="hasAccount">
      Already have an account? <span routerLink="/login">Sign In</span>
    </p>
    <div class="white-box pl-3 pr-3 pt-5 pb-5 mt-5">
      <div class="row">
        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="form-group widget-form-group icon-field">
            <label for=""
              >First Name
              <i class="text-danger">*</i>
            </label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="basicInfoModel.first_name"
                #firstName="ngModel"
                name="first_name"
                placeholder="First Name"
                required
                pattern="[a-zA-Z ]+"
                maxlength="30"
                [ngClass]="{
                  'is-invalid':
                    firstName.invalid && (firstName.dirty || firstName.touched)
                }"
              />
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="material-icons"> person </i>
                </span>
              </div>
            </div>
            <div
              *ngIf="
                firstName.invalid && (firstName.dirty || firstName.touched)
              "
              class="invalid-feedback d-block"
            >
              <div *ngIf="firstName.errors.required">
                First name is required.
              </div>
              <div *ngIf="firstName.errors.pattern">
                Please enter character only.
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="form-group widget-form-group icon-field">
            <label for=""
              >Last Name
              <!-- <i class="text-danger">*</i> -->
            </label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="basicInfoModel.last_name"
                #lastName="ngModel"
                name="last_name"
                placeholder="Last Name"
                pattern="[a-zA-Z ]+"
                maxlength="30"
                [ngClass]="{
                  'is-invalid':
                    lastName.invalid && (lastName.dirty || lastName.touched)
                }"
              />
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="material-icons"> person </i>
                </span>
              </div>
            </div>
            <div
              *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
              class="invalid-feedback d-block"
            >
              <div *ngIf="lastName.errors.required">Last name is required.</div>
              <div *ngIf="lastName.errors.pattern">
                Please enter character only.
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="form-group widget-form-group icon-field">
            <label for=""
              >E-mail
              <i class="text-danger">*</i>
            </label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                (focusout)="availablityCheck()"
                preventCutCopyPaste
                [(ngModel)]="basicInfoModel.email"
                pattern="^[A-Za-z0-9._-]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,4}$"
                name="email"
                placeholder="E-mail"
                required
                #email="ngModel"
                [ngClass]="{
                  'is-invalid': email.invalid && (email.dirty || email.touched)
                }"
              />
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="material-icons"> email </i>
                </span>
              </div>
            </div>
            <div
              *ngIf="email.invalid && (email.dirty || email.touched)"
              class="invalid-feedback d-block"
            >
              <div *ngIf="email.errors.required">Email is required.</div>
              <div *ngIf="email.errors.pattern">Please enter valid email.</div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="form-group widget-form-group icon-field">
            <label for=""
              >Confirm E-mail
              <i class="text-danger">*</i>
            </label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                (blur)="compareEmail()"
                preventCutCopyPaste
                placeholder="Confirm E-mail"
                pattern="^[A-Za-z0-9._-]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,4}$"
                name="confirmEmail"
                required
                [(ngModel)]="confirmEmail"
                #confirmEmailVal="ngModel"
                [ngClass]="{
                  'is-invalid':
                    confirmEmailVal.invalid &&
                    (confirmEmailVal.dirty || confirmEmailVal.touched)
                }"
              />
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="material-icons"> email </i>
                </span>
              </div>
            </div>
            <div
              *ngIf="
                confirmEmailVal.invalid &&
                (confirmEmailVal.dirty || confirmEmailVal.touched)
              "
              class="invalid-feedback d-block"
            >
              <div *ngIf="confirmEmailVal.errors.required">
                Confirm email is required.
              </div>
              <div *ngIf="confirmEmailVal.errors.pattern">
                Please enter valid confirm email.
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="form-group widget-form-group icon-field">
            <label for=""
              >Password
              <i class="text-danger">*</i>
            </label>
            <div class="input-group">
              <input
                type="password"
                class="form-control"
                (blur)="comparePass()"
                preventCutCopyPaste
                [(ngModel)]="basicInfoModel.password"
                name="password"
                placeholder="Password"
                required
                #password="ngModel"
                minlength="2"
                maxlength="25"
                [ngClass]="{
                  'is-invalid':
                    password.invalid && (password.dirty || password.touched)
                }"
              />
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="material-icons"> lock </i>
                </span>
              </div>
            </div>
            <div
              *ngIf="password.invalid && (password.dirty || password.touched)"
              class="invalid-feedback d-block"
            >
              <div *ngIf="password.errors.required">Password is required.</div>
              <div *ngIf="password.errors.minlength">
                Please enter valid password.
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="form-group widget-form-group icon-field">
            <label for=""
              >Confirm Password
              <i class="text-danger">*</i>
            </label>
            <div class="input-group">
              <input
                type="password"
                class="form-control"
                (blur)="comparePass()"
                preventCutCopyPaste
                [(ngModel)]="confirmPassword"
                #confirmPasswordVal="ngModel"
                minlength="10"
                [ngClass]="{
                  'is-invalid':
                    confirmPasswordVal.invalid &&
                    (confirmPasswordVal.dirty || confirmPasswordVal.touched)
                }"
                name="confirmPassword"
                placeholder="Confirm Password"
                required
                maxlength="25"
                minlength="2"
              />
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="material-icons"> lock </i>
                </span>
              </div>
            </div>
            <div
              *ngIf="
                confirmPasswordVal.invalid &&
                (confirmPasswordVal.dirty || confirmPasswordVal.touched)
              "
              class="invalid-feedback d-block"
            >
              <div *ngIf="confirmPasswordVal.errors.required">
                Confirm Password is required.
              </div>
              <div *ngIf="confirmPasswordVal.errors.minlength">
                Please enter valid confirm password.
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="form-group widget-form-group icon-field">
            <label for="">Phone Number <i class="text-danger">*</i></label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="basicInfoModel.phone_number"
                name="phone_number"
                required
                #phoneNumber="ngModel"
                minlength="10"
                appPhoneMask
                [ngClass]="{
                  'is-invalid':
                    phoneNumber.invalid &&
                    (phoneNumber.dirty || phoneNumber.touched)
                }"
                maxlength="15"
              />
              <div class="input-group-prepend">
                <span class="std-code">
                  <img src="../../../../assets/images/usa-country.png" />
                  +1
                  <span class="material-icons dropdown-icon">
                    arrow_drop_down
                  </span>
                </span>
              </div>
            </div>
            <div
              *ngIf="
                phoneNumber.invalid &&
                (phoneNumber.dirty || phoneNumber.touched)
              "
              class="invalid-feedback d-block"
            >
              <div *ngIf="phoneNumber.errors.required">
                Phone number is required.
              </div>
              <div *ngIf="phoneNumber.errors.pattern">
                Please enter number only.
              </div>
              <div *ngIf="phoneNumber.errors.minlength">
                Please enter valid number.
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-12 col-lg-6">
          <div class="form-group widget-form-group icon-field">
            <label for="">Time Zone <i class="text-danger">*</i></label>
            <div class="input-group">
              <!-- <select type="tel" #timeZone="ngModel"
                  [ngClass]="{'is-invalid': timeZone.invalid && (timeZone.dirty || timeZone.touched)}"
                  required class="form-control" placeholder="Time Zone"
                  name="time_Zone" [(ngModel)]="basicInfoModel.timezone">
                     <option value="">Select</option>
                     <option [value]="item.id" *ngFor="let item of timeZoneData">{{item.name}}</option>
                  </select> -->

              <div
                class="btn-group d-flex w-100"
                id="timeZoneSelect"
                dropdown
                (click)="dropdownClicked('timeZoneSelect')"
              >
                <div
                  aria-controls="dropdown-animated"
                  dropdownToggle
                  class="input-group custom-select-value form-control"
                >
                  <span>{{ selectedValue }}</span>
                </div>
                <ul
                  id="dropdown-animated"
                  *dropdownMenu
                  class="dropdown-menu"
                  role="menu"
                  aria-labelledby="button-animated"
                  required
                >
                  <li role="menuitem" *ngFor="let item of timeZoneData">
                    <a
                      class="dropdown-item"
                      [ngClass]="{ active: item.name == selectedValue }"
                      (click)="selectItem(item)"
                      >{{ item.name }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div *ngIf="timeZone.invalid && (timeZone.dirty || timeZone.touched)"
                  class="invalid-feedback d-block">
                  <div *ngIf="timeZone.errors.required">
                     Time zone is required.
                  </div>
               </div> -->
          </div>
        </div>
        <div class="col-sm-6 col-md-12 col-lg-6"></div>
        <div class="col-sm-6 col-md-12 col-lg-6">
          <re-captcha
            [(ngModel)]="basicInfoModel.captcha"
            name="captcha"
            required
            #captchaControl="ngModel"
            (resolved)="resolved($event)"
            (error)="onError($event)"
            errorMode="handled"
          ></re-captcha>
          <!-- <div
            [hidden]="captchaControl.valid || captchaControl.pristine"
            class="error"
          >
            Captcha must be solved
          </div>
         <div [hidden]="!captchaControl.valid" class="success">
            Captcha is valid
          </div>
          <div [hidden]="basicInfoForm.form.valid" class="error">
            The form must be filled out
          </div>
          <div [hidden]="!basicInfoForm.form.valid" class="success">
            The form is valid
          </div>
          <button (click)="basicInfoModel.captcha = ''">Reset Captcha</button> -->
        </div>
      </div>
    </div>
    <hr class="thereHr mt-4" />
    <div class="clearfix">
      <button
        type="submit"
        (click)="submitLevel()"
        *ngIf="stepNo < 1"
        [disabled]="basicInfoForm.invalid || selectedValue == '--Select--'"
        class="
          btn btn-primary
          submit-btn
          mt-2
          small
          theme-btn-shadow
          float-right
        "
      >
        Next
      </button>
      <button
        type="submit"
        (click)="redirectToNext()"
        *ngIf="stepNo >= 1"
        [disabled]="basicInfoForm.invalid || selectedValue == '--Select--'"
        class="
          btn btn-primary
          submit-btn
          mt-2
          small
          theme-btn-shadow
          float-right
        "
      >
        Next
      </button>
    </div>
  </div>
</form>
<div class="loaderDiv" *ngIf="serverBusy">
  <div class="loader-div">
    <div class="circle circle-1"></div>
    <div class="circle circle-2"></div>
  </div>
</div>
