import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { UserService } from '../../authentication/user.service';
import { take, map } from 'rxjs/operators';
import { CommanApiService } from "./commanApi.service"
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/authentication/model/user.model';

@Injectable()
export class AuthGuard implements CanActivate {
  membershipPending: any = null;
  trialMode: any;
  isUserMembershipChecked: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private commanApiService: CommanApiService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    if (this.isCheckUserMembership(state)) {
      if (this.isUserMembershipChecked) {
        if (this.checkUserMembership()) {
          return this.userService.isAuthenticated.pipe(take(1));
        } else {
          this.router.navigate(["/"]);
          this.toastr.error("Access Denied");
          this.sendActive()
          return this.userService.isAuthenticated.pipe(map(bool => !bool));
        }
      } else {
        return this.getccExpiry();
      }
    } else {
      return this.userService.isAuthenticated.pipe(take(1));
    }

    /*
        let currentUser: any = window.localStorage["roleType"];
        if (!currentUser) {
          currentUser = "login";
        }

        if (currentUser) {
          console.log("route.data.roles:=> ", route.data.roles);

          // check if route is restricted by role
          if (
            route.data.roles &&
            route.data.roles.indexOf(currentUser) === -1
          ) {
            // role not authorised so redirect to home page
            // this.router.navigate(["/"]);
            // this.toastr.error("Access Denied");
            // this.sendActive()
            // return this.userService.isAuthenticated.pipe(map(bool => !bool));
            // return this.userService.isAuthenticated.pipe(take(1));
          }
          // authorised so return true
          return this.userService.isAuthenticated.pipe(take(1));
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(["/login"]);
        return this.userService.isAuthenticated.pipe(map(bool => !bool));
    */
  }

  sendActive() {
    this.commanApiService.onActiveMenuUpdate("update");
  }

  isCheckUserMembership(state: RouterStateSnapshot): boolean {
    const prefixUrl = state.url.split("/")[1];
    if (state.url.includes('chat/widget'))
      return false
    switch (prefixUrl) {
      case "campaign":
      case "reviews":
      case "message":
      case "chat":
        return true;
      default:
        return false;
    }
  }

  checkUserMembership(): boolean {
    if (this.trialMode && this.membershipPending) {
      this.toastr.warning("Your trial period is over.");
      return false;
    } else if (this.trialMode) {
      this.toastr.warning("You are in trial mode.");
      return false;
    } else {
      return true;
    }
  }

  getccExpiry(): Observable<boolean> {
    var subject = new Subject<boolean>();
    // fetch data through api
    this.commanApiService.getccExpiry().subscribe(data => {
      this.isUserMembershipChecked = true;
      let userDetail = JSON.parse(window.localStorage['currentUser'])
      userDetail.trialMode = data.trialMode
      userDetail.locked = data.locked
      userDetail.membershipPending = data.membershipPending
      this.trialMode = userDetail.trialMode;
      this.membershipPending = userDetail.membershipPending;
      window.localStorage['currentUser'] = JSON.stringify(userDetail);
      if (this.checkUserMembership()) {
        subject.next(true);
      } else {
        this.router.navigate(["/"]);
        subject.next(false);
      }
    });
    return subject.asObservable();
  }

}
