<div class="pb-4">
    <p class="text-center installImage">
        <img src="../../../../assets/images/install-widget.svg" />
    </p>
    <h2 class="reg-heading text-center">Widget Type</h2>
    <div class="white-box p-3">
        <p class="text-center coupon-text">Copy the following script and paste it into your website's HTML source code before the closing HEAD tag. This code must be inserted into every page you want to show the widget on. Note that the widget will not slow down your web pages. </p>
        <div class="form-group widget-form-group m-0">
            <div class="script-container">
                <div class="script-inner">
                    <code id="text_element">
                        {{widgetToken}}
                    </code>
                </div>

                <p class="m-0 text-right copy-code-btn d-flex align-items-center justify-content-end">
                    <button class="btn btn-primary submit-btn copy-tooltip" (click)="copyCode('text_element',$event)"><i class="material-icons"> file_copy </i>Copy </button></p>
            </div>
        </div>
    </div>
    <hr class="thereHr mt-4" />
    <div class="clearfix btn-wrapper">
        <a href="https://www.chatraven.com/tutorials" class="btn btn-video btn-install tutorial-btn">


            <p class="btn btn previous-btn btn-row mr-3"><span><i class="fa fa-video-camera v-icon mr-3 button-glow" aria-hidden="true"></i></span>
                <span> Here for our tutorials on how to insert header code if you need help </span></p>
        </a>
        <button type="submit" class="btn btn-primary submit-btn  small theme-btn-shadow float-right " (click)="redirectto('/chat/widget')">
            Complete
        </button>
    </div>
</div>