import { Component, OnInit } from '@angular/core';
import { addStaff } from '../registration';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.scss']
})
export class AddStaffComponent implements OnInit {
  addStaffModel = new addStaff()
  firstName = '';
  lastName = '';
  email = '';
  stepNo = 0
  serverBusy = false
  private unsubscribeAll: Subject<any>;
  constructor(private toastr :ToastrService,private userService: UserService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.userService.preStepChangeData.pipe(takeUntil(this.unsubscribeAll)).subscribe(data => {
      if(data){
        this.stepNo = data
      }
    })
    if(window.localStorage["addStaff"]){
      this.addStaffModel = JSON.parse(window.localStorage["addStaff"])
    }
  }

  profilePic(imageInput: any){ 
    this.addStaffModel.profile_pic = ""
    var files = imageInput.target.files;
    this.addStaffModel.profileFile = files[0]
    var fileName = files[0].name
    var file_extension = (fileName.split('.').pop()).toLowerCase();
    if(file_extension != "jpg" && file_extension != "png" && file_extension != "jpeg"){
        this.toastr.error('You can only upload the file with extension jpg, jpeg or png.')
    } else {
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (event :any) => { 
          this.addStaffModel.profile_pic = event.target.result;
        }
    }
  }

  saveStaff(){
    this.serverBusy = true
    let formdata = new FormData();
    this.addStaffModel.phone = this.userService.phoneNumberset(this.addStaffModel.phone)
    formdata.append("first_name",this.addStaffModel.first_name);
    formdata.append("last_name",this.addStaffModel.last_name);
    formdata.append("email",this.addStaffModel.email);
    formdata.append("phone",this.addStaffModel.phone);
    if(this.addStaffModel.profileFile){
      formdata.append("profile_pic",this.addStaffModel.profileFile);
    } else {
      formdata.append("profile_pic",null);
    }
    this.userService.createStaffData(formdata).subscribe(data=>{
      window.localStorage['addStaff'] = JSON.stringify(this.addStaffModel);
      this.userService.onNextSteoData(4,'next')
      this.serverBusy = false
      this.toastr.success(data.message)
    },error=>{
      this.serverBusy = false
      this.toastr.error(error)
    })
  }

  preStep(){
    this.userService.onNextSteoData(4,'pre')
  }

  skip(){
    this.userService.onNextSteoData(4,'next')
  }

  redirectToNext(){
    this.userService.onNextSteoData(4,'next')
  }

  availablityCheck(){
    if(this.addStaffModel.email){
      let inputParam = {
        "email" : this.addStaffModel.email
      }
      this.userService.emailCheck(inputParam).subscribe(data=>{
        this.toastr.success(data.message)
      },err=>{
        this.toastr.error(err)
      })
    }
  }
}
