export class EnvironmentSwitch {
  data: any;
  environmentName = window.location.hostname;
  environmentNameSelection() {
    switch (this.environmentName) {
      case 'localhost':
        this.data = {
          // api_url: "http://localhost/api/v1",
          api_url: "https://api-staging.chatraven.com/api/v1",
          video_url: "https://api.staging.com/storage/messageMedia/",
          // api_url: "https://api.chatraven.com/api/v1",
          // video_url: "https://api.chatraven.com/storage/messageMedia/",
          demo: true,
          encryKey:  '7ExbMAL04bJP4O1tQEFBVVv3qPdIIlPedvDonrneyiE='
          // encryKey:  '7ExbMAL04bJP4O1tQEFBVVv'
        }
        break;
      case 'app.rafraven.com':
        this.data = {
          api_url: "https://api-staging.chatraven.com/api/v1",
          video_url: "https://api-staging.chatraven.com/storage/messageMedia/",
          demo: true,
          encryKey:  '7ExbMAL04bJP4O1tQEFBVVv3qPdIIlPedvDonrneyiE='
        }
        break;
      case 'demo.chatraven.com':
        this.data = {
          api_url: "https://api-staging.chatraven.com/api/v1",
          video_url: "https://api-staging.chatraven.com/storage/messageMedia/",
          demo: true,
          encryKey:  '7ExbMAL04bJP4O1tQEFBVVv3qPdIIlPedvDonrneyiE='
        }
        break;
      case 'app.chatraven.com':
        this.data = {
          api_url: "https://api.chatraven.com/api/v1",
          video_url: "https://api-staging.chatraven.com/storage/messageMedia/",
          demo: false,
          encryKey:  '1jU46K69t07APyU5/WRTPbbmM8dXGamKMsF7BQXhTJA='
        }
        break;
    }
    return this.data;
  }
}
export const environment = {
  production: false,
  api_url: new EnvironmentSwitch().environmentNameSelection().api_url,
  demo: new EnvironmentSwitch().environmentNameSelection().demo,
  video_url: new EnvironmentSwitch().environmentNameSelection().video_url,
  encryKey: new EnvironmentSwitch().environmentNameSelection().encryKey,
  firebase: {
    apiKey: "AIzaSyCQOPGxEY1p-XWMkofxIWDpESxldPXKJxA",
    authDomain: "chatraven-push-notification.firebaseapp.com",
    databaseURL: "https://chatraven-push-notification.firebaseio.com",
    projectId: "chatraven-push-notification",
    storageBucket: "chatraven-push-notification.appspot.com",
    messagingSenderId: "986865305313",
    appId: "1:986865305313:web:9d5c6d266f7112d86559a0",
    measurementId: "G-3TH2DG7F9T"
  },
  openTalk: {
    API_KEY: '46697622'
  }
};
