import { Injectable } from '@angular/core';
import { ApiService } from '../shared';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VirtualWaitingRoomService {
  private chatSelectedSource : BehaviorSubject<any> = new BehaviorSubject(null);
  chatSelectedData = this.chatSelectedSource.asObservable(); 
  onChatSelected(data: any) {
    this.chatSelectedSource.next(data);
  } 

  private chatReadSource : BehaviorSubject<any> = new BehaviorSubject(null);
  readData = this.chatReadSource.asObservable();
  OnCountUpdate(data: any) {
    this.chatReadSource.next(data);
  }

  private removeChatViewSouce : BehaviorSubject<any> = new BehaviorSubject(null);
  removeChatView = this.removeChatViewSouce.asObservable();
  removeChatViewScreen(data: any) {
    this.removeChatViewSouce.next(data);
  }

  private ChatViewNameUpdateSource : BehaviorSubject<any> = new BehaviorSubject(null);
  ChatViewNameUpdateData = this.ChatViewNameUpdateSource.asObservable(); 
  onChatViewNameUpdate(data: any) {
    this.ChatViewNameUpdateSource.next(data);
  } 

  private clearChatSource : BehaviorSubject<any> = new BehaviorSubject(null);
  clearData = this.clearChatSource.asObservable();
  clearChat(data: any) {
    this.clearChatSource.next(data);
  }

  constructor(private apiService : ApiService) { }
  
  getChatGroupList(){
    return this.apiService.get('/chat/myVWRGroups')
  }

  getChatContactList(data,searchData){
    if(data.id=="searchResult"){
      delete data.id
      data.search = searchData
      data.type = "virtualWaitingRoom"
      return this.apiService.post('/search/searchWidgetContacts',data)
    } else {
      return this.apiService.post('/smile/getProgramLeads',data)
    }
  }

  userMessageById(data){
    return this.apiService.post('/smile/getLeadDetail',data)
  }

  messageSend(data){
    return this.apiService.post('/virtualWait/outgoingRequest',data)
  }

  checkAllUnreadMesages(data){
    return this.apiService.post('/smile/markVirtualMessageRead',data)
  }

  sendVideo(data){
    return this.apiService.post('/smile/sendVideoCallLink',data)
  }

  getVrGroupById(data){
    return this.apiService.post('/smile/getProgram',data)
  }

  downloaMsgImg(data){
    return this.apiService.getImageFile('/user/downloadFile',data)
  }

  attachment(data){
    return this.apiService.post('/campaign/UploadMedia',data)
  }

  frameData(data){
    data.phone = this.phoneNumberset(data.phone)
    return this.apiService.post('/widget/incomingRequestLiveChat',data)
  }

  updateUserInfoData(data){
    return this.apiService.post('/smile/updateContact',data)
  }

  deteleChatConversion(data){
    return this.apiService.post('/smile/deleteChatConversionByContactId',data)
  }

  deteleContactById(data){
    return this.apiService.post('/smile/deteleContactById',data)
  }

  refreshPdf(data){
    return this.apiService.post('/virtualWait/reloadPdf',data)
  }

  phoneNumberset(val){
    let a :Number;
    a = val.replace(/[- )(]/g,'');
    return a;
  }
}