import { Injectable } from '@angular/core';


@Injectable()
export class JwtService {

  getToken(): any {
    return window.localStorage['jwtToken'];
  }

  saveToken(token: String) {
    window.localStorage['jwtToken'] = token;
  }

  destroyToken() {
    window.localStorage.removeItem('jwtToken');
    window.localStorage.removeItem('currentUser');
    window.localStorage.removeItem('fcmToken');
    window.localStorage.removeItem('stepNo');
    window.sessionStorage.removeItem('loggedInUser')
    window.localStorage.clear()
    window.sessionStorage.clear()
  }

  getUser(): any {
    var data = ''
    if(window.localStorage['currentUser']){
      data = JSON.parse(window.localStorage['currentUser'])
    }
    return data;
  }

  saveUser(user: any) {
    window.localStorage['currentUser'] = JSON.stringify(user);
    window.sessionStorage['loggedInUser'] = JSON.stringify(user);
  }

  saveNewUser(user: any) {
    window.localStorage['currentNewUser'] = JSON.stringify(user);
    window.sessionStorage['loggedInNewUser'] = JSON.stringify(user);
  }

}