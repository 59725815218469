import { Injectable } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { User } from './model/user.model';
import { JwtService } from './jwt.service';
import { ReplaySubject, BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// { providedIn: 'root' }
@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>(new User());
  public currentUser = this.currentUserSubject.asObservable();

  private isAuthenticatedSubject = new ReplaySubject<boolean>(0);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  private nextStepChangeSource: BehaviorSubject<any> = new BehaviorSubject(null);
  nextStepChangeData = this.nextStepChangeSource.asObservable();
  onNextSteoData(data: any, type: string) {
    let val = {
      "stepNo": data,
      "type": type
    }
    this.nextStepChangeSource.next(val);
  }

  private preStepChangeSource: BehaviorSubject<any> = new BehaviorSubject(null);
  preStepChangeData = this.preStepChangeSource.asObservable();
  onPreStepData(data: any) {
    this.preStepChangeSource.next(data);
  }

  private nextTokenSource: BehaviorSubject<any> = new BehaviorSubject(null);
  nextTokenData = this.nextTokenSource.asObservable();
  onTokenSet(data: any) {
    this.nextTokenSource.next(data);
  }

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService
  ) {
    if (this.jwtService.getToken()) {
      if (this.jwtService.getUser()) {
        this.currentUserSubject.next(this.jwtService.getUser());
        this.isAuthenticatedSubject.next(true);
      } else {
        this.isAuthenticatedSubject.next(false);
      }
    } else {
      this.purgeAuth();
    }
  }

  userSignIn(data, forceLogin) {
    if (forceLogin) {
      return this.apiService.post('/adminLogin', data).pipe(
        tap(
          data => {
            this.setAuth(data)
          },
          error => console.log(error)
        )
      );
    } else {
      return this.apiService.post('/user/login', data).pipe(
        tap(
          data => {
            this.setAuth(data)
          },
          error => console.log(error)
        )
      );
    }
  }

  getCount() {
    return this.apiService.get('/chat/myChatCounts').pipe(
      tap(
        data => {
          this.currentUserSubject.next(this.jwtService.getUser());
          this.isAuthenticatedSubject.next(true);
        },
        error => console.log(error)
      )
    );
  }

  getCode(data) {
    return this.apiService.post('/password/email', data)
  }

  setPass(data) {
    return this.apiService.post('/profile/SetPassword', data)
  }

  resetPass(data) {
    return this.apiService.post('/profile/resetPassword', data)
  }


  setAuth(Response: any) {
    this.jwtService.saveToken(Response["access_token"])
    // Save JWT sent from server in localstorage
    this.setUser(Response);
  }

  setUser(Response: any) {
    this.jwtService.saveUser(Response.result);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next(new User());
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  logout() {
    return this.apiService.post('/user/logoutUser')
  }

  saveLevelOne(data) {
    return this.apiService.post('/user/register', data)
  }

  saveLevelTwo(){
    return this.apiService.get('/user/processTrailMode')
    // return this.apiService.post('/user/processTrailMode')
  }
  joinMembership(data){
    return this.apiService.post('/user/JoinMembership',data)
    // return this.apiService.post('/user/processTrailMode')
  }

  buyPhoneNumber(data) {
    return this.apiService.post('/user/buyNumber', data)
  }

  createStaffData(data) {
    return this.apiService.post('/user/staffregister', data)
  }

  emailCheck(data) {
    return this.apiService.post('/user/checkEmail', data)
  }

  getcamp() {
    return this.apiService.get('/user/campaignNo')
  }

  newUserSignIn(data) {
    return this.apiService.post('/user/login', data).pipe(
      tap(
        data => {
          this.setNewAuth(data)
        },
        error => console.log(error)
      )
    );
  }

  setNewAuth(Response: any) {
    this.jwtService.saveToken(Response["access_token"])
    this.jwtService.saveNewUser(Response.result);
  }

  codeChecking(data) {
    return this.apiService.post('/verify/coupon', data)
  }

  searchNumber(data) {
    return this.apiService.post('/user/getNumber', data)
  }

  saveBusinessData(data) {
    return this.apiService.post('/business/registerNew', data)
  }

  saveWidgetSetting(data) {
    return this.apiService.post('/widget/updateWidgetSettings', data)
  }

  verifyNumber(data) {
    return this.apiService.post('/widget/ValidateNumber', data)
  }

  getAllUserList() {
    return this.apiService.get('/user/listStaff')
  }

  getAllBusiness(data) {
    return this.apiService.post('/business/allBusiness', data)
  }

  getStateList() {
    return this.apiService.get('/states')
  }

  getCityList(data) {
    return this.apiService.get('/cities/' + data)
  }

  activePlan(data) {
    return this.apiService.post('/activePlans', data)
  }

  getPasswordData(data) {
    return this.apiService.post('/validate/SetPassword', data)
  }
  passwordValidate(data) {
    return this.apiService.post('/validate/passwordReset', data)
  }

  getZoneTime() {
    return this.apiService.get('/user/timezone')
  }

  phoneNumberset(val) {
    let a: Number;
    a = val.replace(/[- )(]/g, '');
    return a;
  }

  monthListVal() {
    let obj = [{
      "id": "01",
      "name": "01"
    }, {
      "id": "02",
      "name": "02"
    }, {
      "id": "03",
      "name": "03"
    }, {
      "id": "04",
      "name": "04"
    }, {
      "id": "05",
      "name": "05"
    }, {
      "id": "06",
      "name": "06"
    }, {
      "id": "07",
      "name": "07"
    }, {
      "id": "08",
      "name": "08"
    }, {
      "id": "09",
      "name": "09"
    }, {
      "id": "10",
      "name": "10"
    }, {
      "id": "11",
      "name": "11"
    }, {
      "id": "12",
      "name": "12"
    }]
    return obj
  }
}
