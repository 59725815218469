<div class="pt-4 pb-4">
    <p class="text-center">
       <img src="../../../../assets/images/reg-create-widget.png" />
    </p>
    <h2 class="reg-heading text-center">Create Your Website Widget</h2>
    <form #createWidgetForm="ngForm">
      <h5 class="widget-sub-heading">Widget Information</h5>
      <div class="white-box p-3 mb-4">
         <div class="row">
            <div class="col-sm-6 col-md-12 col-lg-6">
               <div class="form-group widget-form-group">
                  <label for="">Widget Name<span class="text-danger">*</span>
                  <a tooltip="Give your widget a name. This will display on the top of your pop up widget on your website." placement="bottom">
                  <i class="material-icons">
                  info
                  </i>
                  </a>
                  </label>
                  <input type="text" class="form-control" placeholder="Widget Name" name="widget_name"
                  [(ngModel)]="addWidgetModel.widget_name" #widgetNameModel="ngModel" [ngClass]="{'is-invalid': widgetNameModel.invalid && (widgetNameModel.dirty || widgetNameModel.touched)}" required/>
                  <div *ngIf="widgetNameModel.invalid && (widgetNameModel.dirty || widgetNameModel.touched)"
                  class="invalid-feedback d-block">
                  <div *ngIf="widgetNameModel.errors.required">
                     Widget Name is required.
                  </div>
                  </div>
               </div>
            </div>
            <div class="col-sm-6 col-md-12 col-lg-6">
               <div class="form-group widget-form-group">
                  <label for="">Website Name / Business Name<span class="text-danger">*</span>
                  <a tooltip="This is the title of your website or business. It can also be the same as your widget name." placement="bottom">
                  <i class="material-icons">
                  info
                  </i>
                  </a>
                  </label>
                  <input type="text" class="form-control" placeholder="Website Name / Business Name"
                  name="website_name" [(ngModel)]="addWidgetModel.website_name" #websiteNameModel="ngModel" [ngClass]="{'is-invalid': websiteNameModel.invalid && (websiteNameModel.dirty || websiteNameModel.touched)}" required/>
                  <div *ngIf="websiteNameModel.invalid && (websiteNameModel.dirty || websiteNameModel.touched)"
                  class="invalid-feedback d-block">
                  <div *ngIf="websiteNameModel.errors.required">
                     Website name is required.
                  </div>
               </div>
               </div>
            </div>
            <div class="col-sm-6 col-md-12 col-lg-6">
               <div class="form-group widget-form-group m-0">
                  <label for="">Website Url<span class="text-danger">*</span>
                  <a tooltip="Website Url" placement="bottom">
                  <i class="material-icons">
                  info
                  </i>
                  </a>
                  </label>
                  <input type="text" class="form-control" placeholder="Website Url" name="site_url"
                  [(ngModel)]="addWidgetModel.site_url" #websiteUrlModel="ngModel" [ngClass]="{'is-invalid': websiteUrlModel.invalid && (websiteUrlModel.dirty || websiteUrlModel.touched)}" required/>
                  <div *ngIf="websiteUrlModel.invalid && (websiteUrlModel.dirty || websiteUrlModel.touched)"
                  class="invalid-feedback d-block">
                  <div *ngIf="websiteUrlModel.errors.required">
                     Website url is required.
                  </div>
               </div>
               </div>
            </div>
         </div>
      </div>
      <h5 class="widget-sub-heading">Staff Information</h5>
      <div class="white-box p-3 mb-4">
         <div class="row">
            <div class="col-sm-6 col-md-12 col-lg-6">
               <div class="form-group widget-form-group">
                  <label for="">Assign User Name<span class="text-danger">*</span>
                  <a tooltip="Assign the website widget to a ChatRaven user" placement="bottom">
                  <i class="material-icons">
                  info
                  </i>
                  </a>
                  </label>
                  <div class="btn-group d-flex" id="add_user_dropdown" dropdown
                     (click)="dropdownClicked('add_user_dropdown')">
                     <div aria-controls="dropdown-animated" dropdownToggle
                        class="input-group custom-select-value form-control">
                        {{ addWidgetModel.staff_name ? addWidgetModel.staff_name : '--Select--' }}
                     </div>
                     <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu"
                        aria-labelledby="button-animated" required>
                        <li role="menuitem" *ngFor="let item of staffList">
                           <a class="dropdown-item" (click)="selectItem(item)">{{item.full_name}}</a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="col-sm-6 col-md-12 col-lg-6">
               <div class="form-group widget-form-group">
                  <label for="">User Title<span class="text-danger">*</span>
                  <a tooltip="Give the user a title such as 'Support assistant or help desk' this will display next to the user on the widget" placement="bottom">
                  <i class="material-icons">
                  info
                  </i>
                  </a>
                  </label>
                  <input type="text" class="form-control" placeholder="User Title" name="user_title"
                  [(ngModel)]="addWidgetModel.user_title" #userTitleModel="ngModel" [ngClass]="{'is-invalid': userTitleModel.invalid && (userTitleModel.dirty || userTitleModel.touched)}" required/>
                  <div *ngIf="userTitleModel.invalid && (userTitleModel.dirty || userTitleModel.touched)"
                  class="invalid-feedback d-block">
                  <div *ngIf="userTitleModel.errors.required">
                     User title is required.
                  </div>
               </div>
               </div>
            </div>
            <div class="col-sm-6 col-md-12 col-lg-6">
               <div class="form-group widget-form-group icon-field">
                  <label for="">Assign User Phone
                  <a tooltip="Assign the Phone Number that will get ChatRaven messages forwarded to so you can respond to customers while away from your dashboard.This can be cell phone or any sms enabled phone number." placement="bottom">
                  <i class="material-icons">
                  info
                  </i>
                  </a>
                  </label>
                  <div class="input-group" *ngIf="showPhoneNumber">
                     <input minlength="10" appPhoneMask maxlength="15" type="text" class="form-control" name="assign_user_phone" required
                     [(ngModel)]="addWidgetModel.assign_user_phone" [disabled]="true"/>
                     <div class="input-group-prepend">
                        <span class="std-code">
                        <img src="../../../../../assets/images/usa-country.png" />
                        +1
                        <span class="material-icons dropdown-icon">
                        arrow_drop_down
                        </span>
                        </span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <h5 class="widget-sub-heading">General Settings</h5>
      <div class="white-box p-3 mb-4">
         <div class="row">
         
            <div class="col-sm-6 col-md-12 col-lg-6">
               <div class="form-group widget-form-group mb-0">
                  <label class="widget-checkbox mb-0">
                     <input type="checkbox" name="google_review_mobile_hide" [(ngModel)]="addWidgetModel.google_review_enable"/>
                     <span class="widget-check-mark">Enable Google Review
                        <a class="info-link" tooltip="Select this if you want to link your Google My business listing to easily send out review request links users can click to leave reviews." placement="bottom">
                           <i class="material-icons">
                              info
                           </i>
                        </a>
                     </span>
                  </label>
               </div>
            </div>
         </div>
         
      </div>


      <div class="googleReview">
         <h5 class="widget-sub-heading" *ngIf="addWidgetModel.google_review_enable">Add Business to show Google Review</h5>
         <div class="white-box p-3 mb-4" [hidden]="!addWidgetModel.google_review_enable">
            <div class="row">
               <div class="col-md-6">
                  <div class="form-group widget-form-group  m-0">
                     <label for="">Select Business<span class="text-danger">*</span>
                     <a tooltip="Select Business" placement="bottom">
                     <i class="material-icons">
                     info
                     </i>
                     </a>
                     <button class="trpnsparent-btn add-dashed-btn" (click)="openModal(googleReview)">
                     <i class="material-icons">add_location</i></button>
                     </label>
                     <ng-multiselect-dropdown [required]="addWidgetModel.google_review_enable"
                     id ="business_Dropdown"
                     [placeholder]="'Select Business'"
                     name="business_DropdownList"
                     [(ngModel)]="selectedList" 
                     [data]="businessList"
                     [settings]="dropdownSettings"
                     (click)="triggerDropPos('business_Dropdown')">
                     </ng-multiselect-dropdown>
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="form-group widget-form-group  m-0">
                     <label for="">Enter the No. of Seconds to Show Each Review<span class="text-danger">*</span>
                     <a tooltip="Your recent 5 star google reviews can be dynamically displayed in the bottom corner of your webste. Choose how long each review stays up before the next one rotates in." placement="bottom">
                     <i class="material-icons">
                     info
                     </i>
                     </a>
                     </label>
                     <div class="btn-group d-flex" dropdown [dropup]="true">
                        <div aria-controls="dropdown-animated" dropdownToggle class="input-group custom-select-value form-control">
                           {{ addWidgetModel.review_show_interval == '5' ? '5 Sec' : 
                           addWidgetModel.review_show_interval == '10' ? '10 Sec' : 
                           addWidgetModel.review_show_interval == '15' ? '15 Sec' : '--Select--' }}
                        </div>
                        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated"
                           required>
                           <li role="menuitem">
                              <a class="dropdown-item" [ngClass]="{'active': '5' == addWidgetModel.review_show_interval }" 
                                 (click)="selectInterVal('5')">5 Sec</a>
                           </li>
                           <li role="menuitem">
                              <a class="dropdown-item" [ngClass]="{'active': '10' == addWidgetModel.review_show_interval }" 
                                 (click)="selectInterVal('10')">10 Sec</a>
                           </li>
                           <li role="menuitem">
                              <a class="dropdown-item" [ngClass]="{'active': '15' == addWidgetModel.review_show_interval }" 
                                 (click)="selectInterVal('15')">15 Sec</a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <hr class="thereHr mt-4" />
      <div class="clearfix">
         <button type="button" class="btn submit-btn mt-2 small previous-btn float-left" (click)="preStep()">
         Previous
         </button>
         <button (click)="save()" [disabled]="createWidgetForm.invalid" type="submit" *ngIf="stepNo<5" class="btn btn-primary submit-btn mt-2 small theme-btn-shadow float-right">
         Next
         </button>
         <button type="submit" (click)="redirectToNext()" *ngIf="stepNo>=5" class="btn btn-primary submit-btn mt-2 small theme-btn-shadow float-right">
            Next
         </button>
      </div>
    </form>
 </div>
 <ng-template #googleReview>
   <div class="modal-header pt-2 pb-2 pr-3 pl-3 align-items-center">
      <h4 class="modal-title">Search Business On Google To Get Register</h4>
      <button type="button" class="btn btn-primary cancel-btn" aria-label="Close" (click)="cancel()">
         <span class="material-icons">
            close
            </span>
      </button>
   </div>
   <div class="modal-body p-0">
      <div class="row h-100 no-gutters">
         <div class="col-md-5 h-100">
            <div class="business-search pt-2 pb-2 pr-3 pl-3">
               <div class="form-group widget-form-group  m-0">
                  <label for="">Search Your Business</label>
                  <div class="input-group">
                     <input type="text" class="form-control" placeholder="" aria-label=""
                        aria-describedby="basic-addon1" id="pac-input">
                     <div class="input-group-append">
                        <button class="btn btn-link clear" type="button">
                           <i class="material-icons">
                              clear
                           </i>
                        </button>
                        <button class="btn btn-link" type="button">
                           <i class="material-icons">
                              search
                           </i>
                        </button>
                     </div>
                  </div>
               </div>
            </div>
            <div class="business-block-container p-3" *ngIf="widgetVariableModel.fullPlaceData.length>0">
               <div class="business-block p-2 mb-2 " *ngFor="let item of widgetVariableModel.fullPlaceData;let i = index">
                  <h4 class="business-title">
                     {{item.name}}
                  </h4>
                  <p class="business-location">{{item.formatted_address}}</p>
                  <div class="clearfix">
                     <button class="float-left add-business" (click)="openbusinessCreation(i,item,createBusiness)">
                        <i class="material-icons">
                           add
                        </i>
                        Add Business
                     </button>
                     <button class="float-right delete-business">
                        <i class="material-icons">
                           delete
                        </i>
                        Delete
                     </button>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-md-7 h-100">
            <div class="map-container w-100 h-100 pl-2" id="map">
            </div>
         </div>
      </div>
   </div>
</ng-template>


 <!-- create business -->
 <ng-template #createBusiness>
   <div class="modal-header pt-2 pb-2 pr-3 pl-3 align-items-center">
      <h4 class="modal-title">Create Business</h4>
      <button type="button" class="btn btn-primary cancel-btn cancel-business-popup" aria-label="Close"
         (click)="cancel()">
         <span class="material-icons">
            close
            </span>
      </button>
   </div>
   <div class="modal-body p-3">
      <form #addBusiness="ngForm" name="addBusiness">
      <h5 class="widget-sub-heading">Business Details</h5>
      <div class="white-box p-3 mb-4">
         <div class="row">
            <div class="col-md-12">
               <div class="form-group widget-form-group icon-field">
                  <label for="">Business Name<span class="text-danger">*</span>
                     <a  tooltip="Business Name" placement="bottom">
                        <i class="material-icons">
                           info
                        </i>
                     </a>
                  </label>
                  <div class="input-group">
                     <input type="text" required class="form-control" placeholder="Business Name" name="businessName" required [(ngModel)]="addBusinessModel.name"/>
                     <div class="input-group-prepend">
                        <span class="input-group-text">
                           <i class="material-icons">
                              business_center
                           </i>
                        </span>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-md-6">
               <div class="form-group widget-form-group icon-field">
                  <label for="">Website<span class="text-danger">*</span>
                     <a  tooltip="Website" placement="bottom">
                        <i class="material-icons">
                           info
                        </i>
                     </a>
                  </label>
                  <div class="input-group">
                     <input type="url" class="form-control" required placeholder="website" name="website" [(ngModel)]="addBusinessModel.website"/>
                     <div class="input-group-prepend">
                        <span class="input-group-text">
                           <i class="material-icons">
                              language
                           </i>
                        </span>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-md-6">
               <div class="form-group widget-form-group icon-field">
                  <label for="">Email</label><span class="text-danger">*</span>
                  <div class="input-group">
                     <input type="email" class="form-control" required pattern="^[A-Za-z0-9._-]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,4}$"  placeholder="Email" name="email" [(ngModel)]="addBusinessModel.email"/>
                     <div class="input-group-prepend">
                        <span class="input-group-text">
                           <i class="material-icons">
                              email
                           </i>
                        </span>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-md-6">
               <div class="form-group widget-form-group icon-field">
                  <label for="">Phone Number<span class="text-danger">*</span>
                     <a  tooltip="Phone Number" placement="bottom">
                        <i class="material-icons">
                           info
                        </i>
                     </a>
                  </label>
                  <div class="input-group" *ngIf="showPhoneNumber">
                     <input type="tel" required  minlength="10" appPhoneMask maxlength="15" class="form-control" placeholder="Phone Number" name="phone" [(ngModel)]="addBusinessModel.phone"/>
                     <div class="input-group-prepend">
                        <span class="std-code">
                           <img src="../../../../../assets/images/usa-country.png" />
                           +1
                           <span class="material-icons dropdown-icon">
                            arrow_drop_down
                            </span>
                        </span>
                      </div>
                  </div>
               </div>
            </div>


         </div>
      </div>

      <!-- Business Address -->
      <h5 class="widget-sub-heading">Business Address</h5>
      <div class="white-box p-3 mb-4">
         <div class="row">

            <div class="col-md-6">
               <div class="form-group widget-form-group icon-field">
                  <label for="">Street Address<span class="text-danger">*</span>
                     <a  tooltip="Street Address" placement="bottom">
                        <i class="material-icons">
                           info
                        </i>
                     </a>
                  </label>
                  <div class="input-group">
                     <input type="text" required class="form-control" placeholder="Street Address" name="address" [(ngModel)]="addBusinessModel.address"/>
                     <div class="input-group-prepend">
                        <span class="input-group-text">
                           <i class="material-icons">
                              location_on
                           </i>
                        </span>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-md-6">
               <div class="form-group widget-form-group icon-field">
                  <label for="">State<span class="text-danger">*</span>
                     <a  tooltip="State" placement="bottom">
                        <i class="material-icons">
                           info
                        </i>
                     </a>

                  </label>

                  <div class="input-group">
                     <div class="input-group-prepend" style="order: 0;">
                        <span class="input-group-text">
                           <i class="material-icons">
                              location_on
                           </i>
                        </span>
                     </div>

                     <div class="btn-group d-flex grow-1" dropdown [dropup]="true">
                        <div aria-controls="dropdown-animated" dropdownToggle class="input-group custom-select-value form-control">
                           {{ addBusinessModel.state_name ? addBusinessModel.state_name : '--Select--' }}</div>
                        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated"
                           required>
                           <li role="menuitem" *ngFor="let item of widgetVariableModel.stateList">
                              <a class="dropdown-item" [ngClass]="{'active': item.id == addBusinessModel.state }" 
                                 (click)="changeState(item)">{{ item.state }}</a>
                           </li>
                        </ul>
                     </div>

                  </div>

               </div>
            </div>

            <div class="col-md-6">
               <div class="form-group widget-form-group icon-field">
                  <label for="">City<span class="text-danger">*</span>
                     <a  tooltip="City" placement="bottom">
                        <i class="material-icons">
                           info
                        </i>
                     </a>

                  </label>
                  <div class="input-group">
                    
                     <div class="input-group-prepend" style="order: 0;">
                        <span class="input-group-text">
                           <i class="material-icons">
                              location_on
                           </i>
                        </span>
                     </div>

                     <div class="btn-group d-flex grow-1" dropdown [dropup]="true">
                        <div aria-controls="dropdown-animated" dropdownToggle class="input-group custom-select-value form-control">
                           {{ addBusinessModel.city_name ? addBusinessModel.city_name : '--Select--' }}</div>
                        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated"
                           required>
                           <li role="menuitem" *ngFor="let item of widgetVariableModel.cityList">
                              <a class="dropdown-item" [ngClass]="{'active': item.id == addBusinessModel.city }" 
                                 (click)="changeCity(item)">{{ item.city }}</a>
                           </li>
                        </ul>
                     </div>
                  </div>

               </div>
            </div>


            <div class="col-md-6">
               <div class="form-group widget-form-group icon-field">
                  <label for="">Zip Code<span class="text-danger">*</span>
                     <a  tooltip="Zip Code" placement="bottom">
                        <i class="material-icons">
                           info
                        </i>
                     </a>
                  </label>
                  <div class="input-group">
                     <input required pattern="[0-9]+" type="text" class="form-control" placeholder="Zip Code" name="zip" [(ngModel)]="addBusinessModel.zip"/>
                     <div class="input-group-prepend">
                        <span class="input-group-text">
                           <i class="material-icons">
                              location_on
                           </i>
                        </span>
                     </div>
                  </div>
               </div>
            </div>

         </div>


      </div>
      <div class="d-flex justify-content-center mt-3">
         <button class="btn btn-primary submit-btn small theme-btn-shadow" [disabled]="addBusiness.invalid || serverBusy" (click)="saveBusiness()">
            <i class="material-icons"> done </i>
            Submit Details
         </button>
      </div>
      </form>
   </div>
</ng-template>
<div class="loaderDiv" *ngIf="loader">
   <div class="loader-div">
       <div class="circle circle-1"></div>
       <div class="circle circle-2"></div>
   </div>
</div>