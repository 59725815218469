import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessagingService } from './service/messaging.service';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Chatraven';

  constructor(private router : Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((val) => {
      if(val["url"]=='/'){
        console.log(val["url"])
        if(window.localStorage['currentUser']){
          this.router.navigate(['/chat/widget']);
        }
      }
    });
  }
}
export function triggerClickEvent(multiSelectId: any) {
  let boundingRectPos: any = document.getElementById(multiSelectId).getBoundingClientRect();  // get elem pos
  let innerHeightWindow: any = window.innerHeight; // get window height

  if(boundingRectPos) {
    if(boundingRectPos.bottom && innerHeightWindow) {
      let multiSelectWidget = document.getElementById(multiSelectId).getElementsByClassName('multiselect-dropdown')[0]; // get selected container
      let multiSelectDropdownList: any = document.getElementById(multiSelectId).getElementsByClassName("dropdown-list")[0]; // get drop list
      if(boundingRectPos.bottom > innerHeightWindow || (boundingRectPos.bottom + 200) > innerHeightWindow) { // bottom touch condition
        console.log('recahed to the bottom');
        if(multiSelectWidget) {
          let multiSelectWidgetHeight = multiSelectWidget.clientHeight; // get height of selected container
          if(multiSelectDropdownList) {
            multiSelectDropdownList.style.bottom = `${multiSelectWidgetHeight + 1}px`; // set bottom of the drop list
          }
        }
      }
      else {
        console.log('not touched to bottom');
        if(multiSelectDropdownList) {
          multiSelectDropdownList.style.bottom = null; // set bottom of the drop list
        }
      }
    }
  }
}

export function triggerSingleDropdown(dropdownid: any) {
  let boundingRectPos: any = document.getElementById(dropdownid).getBoundingClientRect();  // get elem pos
  let innerHeightWindow: any = window.innerHeight; // get window height
  if(boundingRectPos) {
    if(boundingRectPos.bottom && innerHeightWindow) {
      let multiSelectWidget = document.getElementById(dropdownid); // get selected container
      let multiSelectDropdownList: any = document.getElementById(dropdownid).getElementsByClassName("dropdown-menu")[0]; // get drop list
      if(boundingRectPos.bottom > innerHeightWindow || (boundingRectPos.bottom + 200) > innerHeightWindow) { // bottom touch condition
        if(multiSelectWidget) {
          let multiSelectWidgetHeight = multiSelectWidget.clientHeight; // get height of selected container
          if(multiSelectDropdownList) {
            multiSelectDropdownList.style.top = `auto`; // set top of the drop list
            multiSelectDropdownList.style.bottom = `${multiSelectWidgetHeight + 1}px`; // set bottom of the drop list
          }
        }
      }
      else {
        if(multiSelectDropdownList) {
          multiSelectDropdownList.style.top = '100%'; // set top of the drop list
          multiSelectDropdownList.style.bottom = null; // set bottom of the drop list
        }
      }
    }
  }
}


