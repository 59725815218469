import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  stepOne: Boolean = true;
  authForm: FormGroup;
  passForm: FormGroup;
  token = ""
  formErrors = {
    'email': '',
    'password': ''
  };

  validationMessages = {
    'email': {
      'required': 'This field is required.',
      'pattern': 'Please enter valid email',
    }
  };
  isSubmitting = false;
  message: any = "";
  showConfirmPassword:Boolean =false;
  stepthree:Boolean =false;
  title="";


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService, public toastr: ToastrService,
    private fb: FormBuilder) {
    this.authForm = this.fb.group({
      email: ['', Validators.required]
    }, { updateOn: 'blur' });
    this.passForm = this.fb.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, { updateOn: 'blur' });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
     if(params["token"]){
      this.stepOne =false
      this.token = params["token"]
      this.getUserData()
     }
    });
  }

  getUserData(){
    let inputJson = {
      "token" : this.token
    }
    this.userService.passwordValidate(inputJson).subscribe(data=>{
      if(data){
        this.showConfirmPassword =true;
      }
    },err=>{
      this.toastr.error(err)
      this.message = err
      this.stepthree =true;
      this.router.navigateByUrl('/login');
    })
  }

  submitEmail() {
    const emailRegex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

    if ((this.authForm.value.email).trim() == "") {
      this.toastr.error("Please enter email address")
      return false;
    }
    if (!emailRegex.test((this.authForm.value.email).trim()) && (this.authForm.value.email).trim() != "") {
      this.toastr.error("Please enter valid email address")
      return false;
    }
    this.isSubmitting = true;
    let inputParam = {
      "email": this.authForm.value.email
    }
    this.userService.getCode(inputParam).subscribe(data => {
      this.isSubmitting = false;
      if (data) {
        this.toastr.success("Email Sent Successully");
        this.message = data.message
        this.title = data.title
        this.stepthree = true;
        this.stepOne =false;
      }

    }, error => {
      this.isSubmitting = false;
      this.toastr.error(error)
    })

  }

  submitPasword(){
    if(this.passForm.value.password == this.passForm.value.confirmPassword){
      let json ={
        token: this.token,
        password: this.passForm.value.password
      }
      this.userService.resetPass(json).subscribe(data => {
        this.isSubmitting = false;
        if (data) {
          this.showConfirmPassword =false;;
          this.message = data.message
          this.title = data.title
          this.stepthree = true;
          this.toastr.success(data.message)
          this.router.navigateByUrl('/login');
        }
  
      }, error => {
        this.isSubmitting = false;
        this.toastr.error(error)
      })
    }else{
      this.toastr.error('Password does not match ')
    }

  }


}
