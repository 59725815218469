<footer>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <p class="m-0 text-center copy-right-text">
                    &copy; ChatRaven {{ dateObj | date:'yyyy' }} 
                </p>
            </div>
        </div>
    </div>
</footer>