export class sideChatBarModel {
    "groupList" : Array<any> = [];
    "contactList" : Array<any> = [];
    "resultGroupList" : Array<any> = [];
    "groupActive" : boolean = false;
    "grpId" : string = "";
    "currentPage": number = 0;
}

export class chatViewModel {
    "userActive" : boolean = false;
    "userDetail" : any = {};
    "userMessageData" : Array<any> = [];
    "loginUser" : string = "";
    "sendMsg" : any = "";
    "startRecording" : boolean = true;
    "pauseRecording" : boolean = true;
    "currentPage": number = 0;
}

export class videoModel {
    "mobileSession" : boolean = false;
    "videoLink" : string = "";
    "uCode" : string = "";
    "doctorNumber" : string = "";
}

export class frameModel {
    "name" : string = "";
    "message" : string = "";
    "phone" : any = "";
    "api_token" : string = "";
    "onestep" : boolean = true;
    "ip_address" : any = "";
}