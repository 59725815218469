import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChatModule } from './chat/chat.module';
import { VirtualChatModule } from './virtual-chat/virtual-chat.module';
import { SettingModule } from './setting/setting.module';
import { OpentokModule } from './opentok/opentok.module';
import { CampaignModule } from './campaign/campaign.module'
import  { ReviewsModule } from './reviews/reviews.module'
import { QuickMessageModule } from './quick-message/quick-message.module'
import {VirtualWaitingRoomModule} from './virtual-waiting-room/virtual-waiting-room.module'
import {VirtualSmileModule} from './virtual-smile/virtual-smile.module'
import { NoAuthGuard } from './shared';
import { VirtualWaitFrameComponent } from './virtual-waiting-room/virtual-wait-frame/virtual-wait-frame.component';
const routes: Routes = [
  { path: 'chat', loadChildren: './chat/chat.module#ChatModule'},
  { path: 'virtual', loadChildren: './virtual-chat/virtual-chat.module#VirtualChatModule'},
  { path: 'waitingroom', loadChildren: './virtual-waiting-room/virtual-waiting-room.module#VirtualWaitingRoomModule'},
  { path: 'campaign', loadChildren: './campaign/campaign.module#CampaignModule'},
  { path: 'setting', loadChildren: './setting/setting.module#SettingModule'},
  { path: 'reviews', loadChildren: './reviews/reviews.module#ReviewsModule'},
  { path: 'message', loadChildren: './quick-message/quick-message.module#QuickMessageModule'},
  { path: 'virtualconsult/:token', loadChildren: './virtual-smile/virtual-smile.module#VirtualSmileModule'},
  { path: 'virtualconsult/retake/:tokenUrl', loadChildren: './virtual-smile/virtual-smile.module#VirtualSmileModule'},
  { path: 'video', loadChildren: './opentok/opentok.module#OpentokModule'},
  { path: 'virtualwidget/:api_token', component: VirtualWaitFrameComponent, canActivate: [NoAuthGuard]},
  { path: '', loadChildren: './authentication/auth.module#AuthModule'},
  { path: '**', loadChildren: './authentication/auth.module#AuthModule'},
];

const rootRouting: ModuleWithProviders = RouterModule.forRoot([
], { useHash: false });

@NgModule({
  imports: [RouterModule.forRoot(routes),rootRouting],
  exports: [RouterModule]
})

export class AppRoutingModule { }
