export class addCard {
  "name_on_card": string = "";
  "card_no": string = "";
  "ccExpiryMonth": string = "";
  "ccExpiryYear": string = "";
  "cvvNumber": string = "";
}
import { Component, OnInit, ChangeDetectorRef, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommanApiService } from '../services';
import { ToastrService } from 'ngx-toastr';
import { error } from 'protractor';
import { MessagingService } from 'src/app/service/messaging.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @ViewChild('addCardPopup') addCardPopup: TemplateRef<any>;
  @ViewChild('memberShip') memberShip: TemplateRef<any>;
  monthList = this.commanApiService.monthListVal()
  addCardModel = new addCard()
  SelectedMonth = "Month"
  selectedYear = "Year"
  serverBusy = false
  private unsubscribeAll: Subject<any>;
  chatCount: any = {}
  type = ""
  amount: any = 0
  preSelectedMenu = ""
  userDetail: any
  subMenuList = []
  enable_virtual = null
  vrStatus = null
  modalRef: BsModalRef;
  yearList = []
  activeMainMenu = ""
  activeSubMenuClass = ""
  trialMode: any;
  noOfDaysLeft: any = "";
  timer: NodeJS.Timeout;
  profileMode: any;
  membershipPending: any = null
  constructor(private modalService: BsModalService, private router: Router, private commanApiService: CommanApiService, private toastr: ToastrService,
    private changeDetector: ChangeDetectorRef, private route: ActivatedRoute) {
    this.unsubscribeAll = new Subject();
    this.yearList = []
    let fullYear = (new Date()).getFullYear()
    let i = 0
    for (i = 0; i <= 20; i++) {
      this.yearList.push(fullYear + i)
    }
    if (window.localStorage['currentUser']) {
      this.getVrStatus()
      this.getUserDetail()
      this.userDetail = JSON.parse(window.localStorage['currentUser'])
      this.enable_virtual = this.userDetail.enable_virtual
      this.trialMode = this.userDetail.trialMode
      this.profileMode = this.userDetail.locked
      if (this.userDetail.amount) {
        this.amount = (parseFloat(this.userDetail.amount)).toFixed(2);
      }
    }


  }

  timeBetweenDates(toDate) {
    var dateEntered = toDate;
    var now = new Date();
    var difference = now.getTime() - dateEntered.getTime();

    if (difference <= 0) {

      // Timer done
      clearInterval(this.timer);

    } else {

      var seconds = Math.floor(difference / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);

      hours %= 24;
      minutes %= 60;
      seconds %= 60;
      this.noOfDaysLeft = 14 - days;
    }
  }
  dateChecker(date) {
    var compareDate = new Date(date);
    // compareDate.setDate(compareDate.getDate()); //just for this demo today + 7 days
    setInterval(() => {
      this.timeBetweenDates(compareDate);
    }, 1000);
  }

  getUserDetail() {
    this.commanApiService.userDetailData().subscribe(data => {
      this.userDetail = data.result
      window.localStorage['currentUser'] = JSON.stringify(this.userDetail)
      this.enable_virtual = this.userDetail.enable_virtual
      this.trialMode = this.userDetail.trialMode
      this.membershipPending = this.userDetail.membershipPending
      this.profileMode = this.userDetail.locked
      if (this.trialMode && this.profileMode) {
        this.checkMemberShip();
      }
      this.dateChecker(this.userDetail.created_at);


      if (this.userDetail.amount) {
        this.amount = (parseFloat(this.userDetail.amount)).toFixed(2);
      }
      if (this.userDetail.CCExpired && this.addCardPopup && !this.modalRef) {
        this.router.navigate(['/setting/payment']);
        this.addCard()
      }
    })
  }

  getVrStatus() {
    this.commanApiService.getStatusVr().subscribe(data => {
      this.vrStatus = data.result
      window.localStorage["vrStatusData"] = JSON.stringify(this.vrStatus)
    }, err => {
      this.toastr.error(err)
    })
  }

  ngOnInit() {
    this.commanApiService.chatMessageReceived.pipe(takeUntil(this.unsubscribeAll)).subscribe(data => {
      if (data) {
        if (data["data"]["update_Counts"]) {
          let count = JSON.parse(data["data"].update_Counts)
          if (data["data"].is_virtual == "true") {
            this.chatCount.vrCount = count["virtualSmile"].header_count
          } else if (data["data"].is_virtualWait == "true") {
            this.chatCount.virtualwaitCount = count["virtualWait"].header_count
          } else if (count["widget"]) {
            this.chatCount.widgetCount = count["widget"].header_count
          } else if (count["form"]) {
            this.chatCount.formCount = count["form"].header_count
          } else if (count["other"]) {
            this.chatCount.otherCount = count["other"].header_count
          }
          if (data["data"].lock_accont == "true") {
            this.toastr.warning("Your trial period is over.")
            this.activeMainMenu = 'setting'
            this.activeSubMenuClass = 'membershipHead'
            this.regirectTo('/setting/membership')
          }
          this.changeDetector.detectChanges()
        } else if (data["data"]["paymentFailed"] == "true") {
          this.getUserDetail()
        }
      }
    })
    this.commanApiService.readData.pipe(takeUntil(this.unsubscribeAll)).subscribe(data => {
      if (data) {
        if ((this.router.url).indexOf('widget') > 0) {
          this.type = 'widget'
        } else if ((this.router.url).indexOf('form') > 0) {
          this.type = 'form'
        } else if ((this.router.url).indexOf('other') > 0) {
          this.type = 'other'
        } else if ((this.router.url).indexOf('virtual') > 0) {
          this.type = 'al/chat'
        } else if ((this.router.url).indexOf('waitingroom') > 0) {
          this.type = 'ngRoom/chat'
        }
        if(this.type == "widget" && this.chatCount.widgetCount){
          this.chatCount.widgetCount = this.chatCount.widgetCount - 1
        }
        if (this.type == "form" && this.chatCount.formCount) {
          this.chatCount.formCount = this.chatCount.formCount - 1
        }
        if (this.type == "other" && this.chatCount.otherCount) {
          this.chatCount.otherCount = this.chatCount.otherCount - 1
        }
        if (this.type == "al/chat" && this.chatCount.vrCount) {
          this.chatCount.vrCount = this.chatCount.vrCount - 1
        }
        if (this.type == "ngRoom/chat" && this.chatCount.virtualwaitCount) {
          this.chatCount.virtualwaitCount = this.chatCount.virtualwaitCount - 1
        }
        this.changeDetector.detectChanges()
      }
    })
    this.commanApiService.updateFundData.pipe(takeUntil(this.unsubscribeAll)).subscribe(data => {
      if (data) {
        let userDetal = JSON.parse(window.localStorage['currentUser'])
        if (userDetal.amount) {
          this.amount = (parseFloat(userDetal.amount)).toFixed(2);
        }
        this.changeDetector.detectChanges()
      }
    })
    this.commanApiService.statusUpdateData.pipe(takeUntil(this.unsubscribeAll)).subscribe(data => {
      if (data) {
        this.getVrStatus()
        this.changeDetector.detectChanges()
      }
    })

    this.commanApiService.activeMenuData.pipe(takeUntil(this.unsubscribeAll)).subscribe(data => {
      if (data) {
        let val = this.commanApiService.activeTabList()
        this.activeMainMenu = val.mainMenu
        this.activeSubMenuClass = val.subMenu
        this.changeDetector.detectChanges()
      }
    })
    this.getMsgCount()
  }



  ngAfterViewInit() {
    let val = this.commanApiService.activeTabList()
    this.activeMainMenu = val.mainMenu
    this.activeSubMenuClass = val.subMenu
    this.changeDetector.detectChanges()
    if (this.userDetail.CCExpired) {
      this.router.navigate(['/setting/payment']);
      this.addCard()
    }
  }

  chatType(val) {
    if (val == 'other') {
      this.checkCase(val)
    } else {
      this.type = val
      this.checkCCExpiry('/chat/' + val, 'inbox', null)
    }
  }

  vrchatType(val) {
    let titleVal = ""
    let navigateTo = ""
    if (!this.vrStatus.vrNo) {
      titleVal = "Please buy virtual consult phone number, to review the virtual consult chat panel ?"
      navigateTo = '/setting/sms'
      this.activeSubMenuClass = 'sms'
    } else if (!this.vrStatus.vrStatus) {
      titleVal = "Please enable the virtual consult , to review the virtual consult chat panel ?"
      navigateTo = '/setting/virtualConsultEnable'
      this.activeSubMenuClass = 'virtualConsultEnable'
    }
    if (titleVal) {
      swal.fire({
        title: titleVal,
        icon: 'info',
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCancelButton: true
      }).then((result) => {
        if (result.value) {
          this.activeMainMenu = 'setting'
          this.router.navigate([navigateTo]);
          // this.activeClass('setting',null)
        }
      })
    } else {
      this.type = val
      this.activeMainMenu = 'virtualConsult'
      this.activeSubMenuClass = ""
      this.router.navigate(['/virtual/' + val]);
      // this.activeClass('virtualConsult',null)
    }
  }

  vwrchatType(val) {
    let titleVal = ""
    let navigateTo = ""
    if (!this.vrStatus.vrWaitNo) {
      titleVal = "Please buy virtual wait room phone number, to review the virtual wait room chat panel ?"
      navigateTo = '/setting/sms'
      this.activeSubMenuClass = "sms"
    } else if (!this.vrStatus.vrWaitStatus) {
      titleVal = "Please enable the virtual wait room , to review the virtual wait room chat panel ?"
      navigateTo = '/setting/virtualWaitRoomEnable'
      this.activeSubMenuClass = "virtualWaitRoomEnable"
    }
    if (titleVal) {
      swal.fire({
        title: titleVal,
        icon: 'info',
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCancelButton: true
      }).then((result) => {
        if (result.value) {
          this.activeMainMenu = 'setting'
          this.router.navigate([navigateTo]);
          // this.activeClass('setting',null)
        }
      })
    } else {
      this.type = val
      this.activeMainMenu = 'virtualWaitingRoom'
      this.activeSubMenuClass = ""
      this.router.navigate(['/waitingroom/' + val]);
      // this.activeClass('virtualWaitingRoom',null)
    }
  }

  getMsgCount() {
    this.commanApiService.getCount().subscribe(data => {
      this.chatCount = data
    }, error => {
      this.toastr.error(error)
    })
  }

  regirectTo(val) {
    this.router.navigate([val]);
  }

  checkCCExpiry(val,item,subMenu){
    this.serverBusy = true
    let cookieVal = this.getCookie('no_of_days')
    this.commanApiService.getccExpiry().subscribe(data => {
      let userDetail = JSON.parse(window.localStorage['currentUser'])
      userDetail.CCExpired = data.result
      userDetail.trialMode = data.trialMode
      userDetail.locked = data.locked
      userDetail.membershipPending = data.membershipPending
      this.userDetail = userDetail
      this.trialMode = this.userDetail.trialMode
      this.profileMode = this.userDetail.locked
      this.membershipPending = this.userDetail.membershipPending
      window.localStorage['currentUser'] = JSON.stringify(userDetail)
      if (this.userDetail.CCExpired && !this.trialMode) {
        this.serverBusy = false
        this.addCard()
      } else {
        this.serverBusy = false
        if (val == 'vrchat') {
          if (this.trialMode && this.membershipPending) {
            this.toastr.warning("Your trail period is over.")
            this.activeMainMenu = 'setting'
            this.activeSubMenuClass = 'membershipHead'
            this.regirectTo('/setting/membership')
          } else if (this.trialMode) {
            // this.dateChecker(this.userDetail.created_at);
            this.checkMemberShip();
            this.serverBusy = false;
            return false;
          } else {
            this.vrchatType('chat')
          }
        } else if (val == 'vwrchat') {
          if (this.trialMode && this.membershipPending) {
            this.toastr.warning("Your trail period is over.")
            this.activeMainMenu = 'setting'
            this.activeSubMenuClass = 'membershipHead'
            this.regirectTo('/setting/membership')
          } else if (this.trialMode) {
            // this.dateChecker(this.userDetail.created_at);

            this.checkMemberShip();
            this.serverBusy = false;
            return false;
          } else {
            this.vwrchatType('chat')
          }
        } else {
          if (this.trialMode && this.membershipPending && (subMenu != 'staffMember' && subMenu != 'sms' && subMenu != 'payment' && subMenu != 'widget') && this.type != 'widget') {
            this.toastr.warning("Your trail period is over.")
            this.activeMainMenu = 'setting'
            this.activeSubMenuClass = 'membershipHead'
            this.regirectTo('/setting/membership')
          } else if (this.trialMode && (subMenu != 'staffMember' && subMenu != 'sms' && subMenu != 'payment' && subMenu != 'widget') && this.type != 'widget') {
            // this.dateChecker(this.userDetail.created_at);
            this.checkMemberShip();
            this.serverBusy = false;
            return false;
          } else {
            this.activeMainMenu = item
            this.activeSubMenuClass = subMenu
            // this.activeClass(item,subMenu)
            this.router.navigate([val]);
          }
        }
      }
    }, err => {
      this.serverBusy = false
      this.toastr.error(err)
    })
  }

  checkCase(val) {
    // this.serverBusy = true
    this.commanApiService.getccExpiry().subscribe(data => {
      let userDetail = JSON.parse(window.localStorage['currentUser'])
      userDetail.trialMode = data.trialMode
      userDetail.locked = data.locked
      userDetail.membershipPending = data.membershipPending
      this.userDetail = userDetail
      this.trialMode = this.userDetail.trialMode
      this.profileMode = this.userDetail.locked
      this.membershipPending = this.userDetail.membershipPending
      window.localStorage['currentUser'] = JSON.stringify(userDetail)
      if (this.trialMode && this.membershipPending) {
        this.toastr.warning("Your trial period is over.")
        this.activeMainMenu = 'setting'
        this.activeSubMenuClass = 'membershipHead'
        this.serverBusy = false
        this.regirectTo('/setting/membership')
      } else if (this.trialMode) {
        // this.dateChecker(this.userDetail.created_at);
        this.checkMemberShip();
        this.serverBusy = false;
        return false;
      } else {
        this.serverBusy = false;
        this.checkCamp(val)
      }
    }, err => {
      this.serverBusy = false
      this.toastr.error(err)
    })
  }


  ngOnDestroy() {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }

  addCard() {
    let config = {
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'custom-modal addFund modal-dialog-centered'
    };
    this.modalRef = this.modalService.show(this.addCardPopup, config);
  }
  checkMemberShip() {
    let config = {
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'custom-modal addFund modal-dialog-centered'
    };
    this.modalRef = this.modalService.show(this.memberShip, config);
  }

  selectMonth(item) {
    this.SelectedMonth = item;
    this.addCardModel.ccExpiryMonth = item
  }
  selectYear(item) {
    this.selectedYear = item;
    this.addCardModel.ccExpiryYear = item
  }

  submitClick = false

  submitLevel() {
    this.submitClick = true
    this.commanApiService.addCardNew(this.addCardModel).subscribe(data => {
      this.addCardModel = new addCard()
      this.userDetail.CCExpired = 0
      window.localStorage['currentUser'] = JSON.stringify(this.userDetail)
      this.modalRef.hide()
      this.submitClick = false
      this.toastr.success(data.message)
    }, err => {
      this.submitClick = false
      this.toastr.error(err)
    })
  }

  closeDiv() {
    this.modalRef.hide()
  }

  cancelMembership() {
    this.modalRef.hide()
    var expires = "";
    var days = 1
    var name = "no_of_days"
    var value = this.noOfDaysLeft
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  showCheck = true
  checkCamp(val) {
    // this.serverBusy = true
    this.showCheck = true
    this.commanApiService.getcamp().subscribe(data => {
      this.serverBusy = false
      if (!data.status) {
        this.showCheck = true
        swal.fire({
          title: "SMS campaigns require a separate phone number. Please purchase one to use this service.",
          icon: 'info',
          allowOutsideClick: false,
          showConfirmButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          showCancelButton: true
        }).then((result) => {
          if (result.value) {
            this.activeMainMenu = 'setting'
            this.router.navigate(['/setting/sms']);
            // this.activeClass('setting',null)
          }
        })
      } else {
        if (val) {
          this.type = val
          this.checkCCExpiry('/chat/' + val, 'inbox', null)
        } else {
          this.showCheck = false
        }
      }
    }, err => {
      this.serverBusy = false
      this.showCheck = true
      this.toastr.error(err)
    })

    // this.changeDetector.detectChanges()
  }

  subscribePlan() {
    this.closeDiv();
    this.activeMainMenu = 'setting'
    this.activeSubMenuClass = 'membershipHead'
    this.regirectTo('/setting/membership')
    // this.commanApiService.revokMembership().subscribe(data=>{
    //   if(data){
    //     window.localStorage['currentUser'] =  JSON.stringify(data.result)
    //     this.toastr.success(data.message)
    //   }
    // },error=>{
    //   this.toastr.error(error)
    // })
  }
  unSubscribePlan() {
    this.closeDiv();
    swal.fire({
      title: "Are you Sure you want to close your account",
      text: "Once your account will close, all your contacts and messages will remove no option to recover.",
      icon: 'info',
      allowOutsideClick: false,
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        this.commanApiService.closeAccount().subscribe(data => {
          this.toastr.success(data.message)
          this.router.navigate(['/logout']);
        }, error => {
          this.toastr.error(error)
        })
      } else {
        this.checkMemberShip()
      }
    })
  }

}

