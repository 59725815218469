<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-5">
            <div class="d-flex align-items-center login-left ">
                <a class="login-logo">
                    <img src="../../../assets/images/logo.png" alt="logo" />
                </a>
                <div class="row w-100">
                    <div class="col-12">

                        <!-- reset password step 1 -->


                        <div class="login-form-container " *ngIf="stepOne">
                            <h2 class="font-weight-bold mb-2">
                                Reset Password
                            </h2>
                            <div class="sign-info mb-4">
                                <span class="">Enter your registered email to get verification code</span>
                            </div>
                            <form class="" [formGroup]="authForm" autocomplete="off" novalidate="">
                                <div class="form-group form-field-wrap hasview-btn">
                                    <input type="text" class="form-control pl-0 f-w-500 rounded-0"
                                        id="exampleInputEmail1" placeholder="" name="email" formControlName="email"
                                        required />
                                    <label class="f-w-500 m-0 position-absolute"
                                        for="exampleInputPassword1">Email</label>
                                </div>

                                <div class="text-center">
                                    <button type="button" (click)="submitEmail()"
                                        class="btn btn-primary submit-btn  mb-3 m-3">
                                        Reset Password
                                        <span *ngIf="isSubmitting"><i class="fa fa-circle-o-notch fa-spin"
                                                aria-hidden="true"></i></span>
                                    </button>
                                </div>

                            </form>

                            <div class="sign-info text-center">
                                <span class="">Back to <a routerLink="/login">Login</a></span>
                            </div>
                        </div>


                        <!-- step 2 -->
                        <div class="login-form-container" *ngIf="showConfirmPassword">
                            <h2 class="font-weight-bold mb-2">
                                Set Profile Password
                            </h2>
                            <div class="sign-info mb-4">
                                <span class="">Enter verification code that sent to your email.</span>
                            </div>
                            <form class="" [formGroup]="passForm" autocomplete="off" novalidate="">
                                <div class="form-group form-field-wrap hasview-btn">
                                    <button class="view-btn view-password" type="button" onclick="viewPassword(this)">
                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                        <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                    </button>
                                    <input type="password" class="form-control pl-0 f-w-500 rounded-0"
                                        id="exampleInputPassword1" placeholder="" type="password"
                                        formControlName="password" name="password" required />
                                    <label class="f-w-500 m-0 position-absolute" for="exampleInputPassword1">New
                                        Password </label>
                                </div>
                                <div class="form-group form-field-wrap hasview-btn">
                                    <button class="view-btn view-password" type="button" onclick="viewPassword(this)">
                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                        <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                    </button>
                                    <input type="password" class="form-control pl-0 f-w-500 rounded-0"
                                        formControlName="confirmPassword" id="exampleInputPassword2" placeholder=""
                                        type="password" name="password" required />
                                    <label class="f-w-500 m-0 position-absolute" for="exampleInputPassword2">Confirm
                                        Password </label>
                                </div>
                                <div class="text-center">
                                    <button type="button" (click)="submitPasword()" [disabled]="passForm.invalid"
                                        class="btn btn-primary submit-btn  mb-3 m-3">
                                        Reset My Password
                                    </button>
                                </div>
                            </form>
                        </div>

                        <!-- step 3 success -->

                        <div class="login-form-container " *ngIf="stepthree">
                            <h2 class="font-weight-bold mb-2">
                                {{title}}
                            </h2>
                            <div class="sign-info mb-4">
                                <span class="">{{message}}</span>
                            </div>
                            <div class="text-center">
                                <button type="button" routerLink="/login" class="btn btn-primary submit-btn  mb-3 m-3">
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="log-in-footer">
                    <p class="m-0 small">
                        &copy;CHATRAVEN
                    </p>
                </div>
            </div>
        </div>
        <!-- <div class="col-xl-8 col-lg-7 col-md-7">
          <div class="d-flex align-items-center login-right-content ">
                <div class="w-75 right-inner">
                    <h2>A new and better way to chat, engage and retain customers
                    </h2>
                    <p class="mb-0 mt-3">With our sms chatting, virtual consults, virtual waiting room, and texting
                        campaigns.
                    </p>
                </div>
            </div> 
        </div> -->
   </div>
     <img src="../../../assets/images/frp-bg.png" class="login-bg" alt="logo" />
    <img src="../../../assets/images/frp-bg2.png" class="login-bg2" alt="logo" />
</div>