import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-installation',
  templateUrl: './installation.component.html',
  styleUrls: ['./installation.component.scss']
})
export class InstallationComponent implements OnInit {
  widgetToken = `<script type="text/javascript">window.Chatraven = window.Chatraven || {};window.Chatraven.key = "b9btGTzv"; (function(d, script, t) {script = d.createElement("script"); script.type = "text/javascript";script.defer = true;script.src = "https://api.chatraven.com/widget/widget.js"; d.getElementsByTagName("head")[0].appendChild(script);}(document));</script>`
  private unsubscribeAll: Subject<any>;

  constructor(private router: Router, private userService: UserService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.userService.nextTokenData.pipe(takeUntil(this.unsubscribeAll)).subscribe(data => {
      if (data) {
        let token = data
        this.widgetToken = `<script type="text/javascript">window.Chatraven = window.Chatraven || {};window.Chatraven.key = "${token}"; (function(d, script, t) {script = d.createElement("script"); script.type = "text/javascript";script.defer = true;script.src = "https://api.chatraven.com/widget/widget.js"; d.getElementsByTagName("head")[0].appendChild(script);}(document));</script>`
      }
    })
  }
  copyCode(elementId, event) {
    // Create an auxiliary hidden input
    var aux = document.createElement("input");

    // Get the text from the element passed into the input
    aux.setAttribute("value", document.getElementById(elementId).innerHTML.replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, "\""));

    // Append the aux input to the body
    document.body.appendChild(aux);

    // Highlight the content
    aux.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the input from the body
    document.body.removeChild(aux);
    event.target.classList.add('copied');
    setTimeout(function () {
      event.target.classList.remove('copied');
    }, 4000);
  }

  redirectto(val) {
    let token = window.localStorage['jwtToken']
    let currentUser = window.localStorage['currentNewUser']
    let loggedInUser = window.sessionStorage['loggedInNewUser']
    window.localStorage.clear()
    window.sessionStorage.clear()
    window.localStorage['jwtToken'] = token;
    window.localStorage['currentUser'] = currentUser;
    window.sessionStorage['loggedInUser'] = loggedInUser;
    this.getMsgCount()
  }

  getMsgCount(){
    this.userService.getCount().subscribe(data=>{
      let url = data["activePath"]
      this.router.navigate([url]);
    },error=>{
      // this.toastr.error(error)
    })
  }
}
