import { Component, OnInit, TemplateRef  } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../user.service';
import { environment } from '../../../../environments/environment'; 

@Component({
  selector: 'app-search-number',
  templateUrl: './search-number.component.html',
  styleUrls: ['./search-number.component.scss']
})
export class SearchNumberComponent implements OnInit {
  selectedValue = 'Area Code';
  serverBusy = false
  totalItems = 5;
  currentPage = 4;
  searchNumberVal = ""
  modalRef: BsModalRef;
  numberList = []
  friendlyName = ""
  phoneNumber = ""
  amount = "$5"
  stepNo = 0
  demo = false
  private unsubscribeAll: Subject<any>;
  callForwardingNumber : any = ""
  setPage(pageNo: number): void {
    this.currentPage = pageNo;
  }
  selectItem(item){
    this.selectedValue = item;
  }
  constructor(private modalService: BsModalService,private toastr :ToastrService,private userService: UserService) {
    this.unsubscribeAll = new Subject();
    this.demo = environment.demo
  }

  openModal(val,forwardModel: TemplateRef<any>) {
    this.friendlyName = val.friendlyName
    this.phoneNumber = val.phoneNumber
    let config = {
      ignoreBackdropClick: true,
      class: 'buyNumber custom-modal'
    };
    this.modalRef = this.modalService.show(forwardModel,config);
  }

  ngOnInit(): void {
    this.userService.preStepChangeData.pipe(takeUntil(this.unsubscribeAll)).subscribe(data => {
      if(data){
        this.stepNo = data
      }
    })
    if(window.localStorage["searchNumber"]){
      let val = JSON.parse(window.localStorage["searchNumber"])
      this.selectedValue = val.type
      this.searchNumberVal = val.search
      this.phoneNumber = val.phoneNumber
    }
  }

  searchData(){
    this.serverBusy = true
    let inputParam = {
      "next_step" : 3,
    }
    
    if(this.selectedValue == 'Area Code'){
      inputParam["areaCode"] = this.searchNumberVal
    } else {
      inputParam["contains"] = this.searchNumberVal
    }
   
    this.userService.searchNumber(inputParam).subscribe(data=>{
      this.serverBusy = false
      this.numberList = data.result
      if(data.result.length==0){
        this.toastr.error(data.message)
      }
    },err=>{
      this.serverBusy = false
      this.toastr.error(err)
    })
  }

  validNumberBuy(){
    this.serverBusy = true
    if(this.callForwardingNumber){
      let inputParam = {
        "number" : this.userService.phoneNumberset(this.callForwardingNumber)
      }
      this.userService.verifyNumber(inputParam).subscribe(data=>{
        this.buyNumber()
      },err=>{
        this.serverBusy = false
        this.callForwardingNumber = ""
        this.toastr.error(err)
      })
    } else {
      this.buyNumber()
    }
  }

  buyNumber(){
    let inputParam = {
      "phoneNumber": this.phoneNumber,
      "friendly_name":null,
      "farwardCallNo": this.callForwardingNumber,
      "next_step":3,
      "demo": this.demo,
      "trialMode":true
    }
    inputParam.farwardCallNo = this.userService.phoneNumberset(inputParam.farwardCallNo)
    this.userService.buyPhoneNumber(inputParam).subscribe(data=>{
      let obj = {
        "type" : this.selectedValue,
        "search" : this.searchNumberVal,
        "phoneNumber" : this.phoneNumber
      }
      window.localStorage['searchNumber'] = JSON.stringify(obj);
      this.modalRef.hide()
      this.userService.onNextSteoData(3,'next')
      this.serverBusy = false
     this.toastr.success(data.message)
    },err=>{
      this.serverBusy = false
      this.callForwardingNumber = ""
      this.toastr.error(err)
    })
  }

  preStep(){
    this.userService.onNextSteoData(3,'pre')
  }

  redirectToNext(){
    this.userService.onNextSteoData(3,'next')
  }
}
