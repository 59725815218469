import { Injectable,EventEmitter } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { ApiService, CommanApiService } from '../shared';
import { HubConnection } from '@aspnet/signalr';  
import * as signalR from '@aspnet/signalr';
@Injectable()

export class MessagingService {
  userData : any = {}
  tokenGetFormFire = ""
  private _hubConnection: HubConnection; 
  connectionEstablished = new EventEmitter<Boolean>();  
  private chatMessageSendStatusSource = new BehaviorSubject(null);
  chatMessageSendStatus = this.chatMessageSendStatusSource.asObservable();

  private chatMessageReceivedSource = new BehaviorSubject(null);
  chatMessageReceived = this.chatMessageReceivedSource.asObservable();

  private virtualChatMessageReceivedSource = new BehaviorSubject(null);
  virtualChatMessageReceived = this.chatMessageReceivedSource.asObservable();

  constructor(private angularFireMessaging: AngularFireMessaging,private apiService : ApiService,private commanService : CommanApiService) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )

    // navigator.serviceWorker.addEventListener('message',this.receiveMessageCheck.bind(this))
    if(window.localStorage["currentUser"]){
      this.userData = JSON.parse(window.localStorage["currentUser"])
    }
    this.createConnection()
    this.registerOnServerEvents();  
    this.startConnection();  
  }

  createConnection() {  
    this._hubConnection = new signalR.HubConnectionBuilder()
    .withUrl("https://notification.referralraven.com:8445/ChatHub").build();  
  }  

  startConnection(): void {  
    this._hubConnection.start().then(() => {  
      // console.log('Hub connection started',signalR.HubConnectionState.Connected);  
      // console.log('Hubssdff',this._hubConnection.state);
      if(this._hubConnection.state==signalR.HubConnectionState.Connected){
        let loginId = ""
        if(this.userData){
          if(this.userData.admin_id){
            loginId = "CRN_"+this.userData.admin_id
          } else {
            loginId = "CRN_"+this.userData.id
          }
        }
        this._hubConnection.invoke("addGroup", loginId).then(function (data) {
          // console.log('channle set!',loginId);
        }).catch(function (err) {
          return console.error(err.toString());
        });
      }
    }).catch(err => {  
      console.log('Error while establishing connection, retrying...');  
      setTimeout(function () { this.startConnection(); }, 5000);  
    });  
  }

  disconnect() {
    this._hubConnection.stop();
  }

  registerOnServerEvents(): void {  
    this._hubConnection.on('ReceiveMessage', (user,messsage) => {  
      // console.log('get',messsage);
      this.getMessage(messsage)
    });  
  }  

  getMessage(val){
    let inputParam = {
      "id" : val
    }
    this.commanService.getNoti(inputParam).subscribe(data=>{
      let firebaseMessagingData = data.result
      let notiType = firebaseMessagingData['data'].type;
      if(notiType=='SmsStatus'){
        this.chatMessageSendStatusSource.next(firebaseMessagingData)
      } else {
        this.chatMessageReceivedSource.next(firebaseMessagingData);
        this.commanService.OnChatReceived(firebaseMessagingData)
        // console.log(firebaseMessagingData["data"].msg_role)
        if(!firebaseMessagingData["data"].msg_role){
          this.customMessage(firebaseMessagingData)
        }
      }
    },err=>{
      console.log(err)
    })
  }

  updateTokenFormServer() {
    let t = this
    let token = ""
    let openRequest = window.indexedDB.open("firebase-messaging-database");
    openRequest.onsuccess = function(event) {
      var db = event.target["result"];
      if(db.objectStoreNames.length>0){
        var transaction = db.transaction(['firebase-messaging-store'], "readonly");
        var objectStore = transaction.objectStore('firebase-messaging-store');
        objectStore.openCursor().onsuccess = function(event) {
          var cursor = event.target.result; 
          if(cursor) {
            token = cursor.value["token"]
            cursor.continue();
          } else {
            if(token){
              t.saveToken(token)
            } else {
              t.saveToken(t.tokenGetFormFire)
            }
          }
        };
      } else {
        t.saveToken(t.tokenGetFormFire)
      }
    };
    openRequest.onerror = function(event) {
      console.log(event)
      t.saveToken(t.tokenGetFormFire)
    }
  }
 
  requestPermission(){
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.tokenGetFormFire = token
        this.updateTokenFormServer()
      },
      (err) => {
        console.log('Unable to get permission to notify.',err);
      }
    );
  }

  saveToken(token){
    let inputParam = {
      "firebaseToken" : token
    }
    this.commanService.saveFireBaseToken(inputParam).subscribe(response => {
      localStorage.setItem("fcmToken",token);
    },error=>{
      console.log(error)
    })
  }

  receiveMessageCheck(event) {
    if(event.data != null){
      var type = event.data.firebaseMessagingType
      if(type=="push-msg-received"){
        var firebaseMessagingData = event.data.firebaseMessagingData
        this.chatMessageReceivedSource.next(firebaseMessagingData);
        this.commanService.OnChatReceived(firebaseMessagingData)
        if(!firebaseMessagingData["data"].msg_role){
          this.customMessage(firebaseMessagingData)
        }
      }
    }
  }

  customMessage(payload:any) {
    
    let notify_data = payload['notification'];
    let title = notify_data['title'];
    let options = {
      body: notify_data['body'],
      icon: "./assets/images/logo.png",
      badge: "./assets/images/logo.png",
      // image: "./assets/images/logo.png",
      audio: "https://api.chatraven.com/audio.mp3",
      sound: "https://api.chatraven.com/audio.mp3",
    }
    let notify: Notification = new Notification(title,options)
    notify.onclick = event => {
      event.preventDefault();
      window.location.href =  notify_data['click_action'];
    }
  }
}
