import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';


import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor() { }

  decodeAPIResponse(data: string) {
    if (data != null) {
      return this.decrypt(data);
    } else {
      return false;
    }
  }

  decrypt(encryptStr){
    encryptStr = CryptoJS.enc.Base64.parse(encryptStr);
    let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);
    encryptData = JSON.parse(encryptData); 
    let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
    var decrypted = CryptoJS.AES.decrypt(encryptData.value,  CryptoJS.enc.Base64.parse(environment.encryKey), {
        iv : iv,
    });
    decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
    return JSON.parse(decrypted)
  }

  encodeAPIReqestData(data: any) {
    if (typeof data === "object") {
      return this.encrypt(JSON.stringify(data));
    } else {
      return false;
    }
  }

  encrypt(data){
    let iv = CryptoJS.lib.WordArray.random(16)
    let key = CryptoJS.enc.Base64.parse(environment.encryKey);
    let options = {
        iv: iv,
    };
    let encrypted = CryptoJS.AES.encrypt(data, key, options);
    encrypted = encrypted.toString();
    iv = CryptoJS.enc.Base64.stringify(iv);
    let result : any = {
        iv: iv,
        value: encrypted,
        mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString()
    }
    result = JSON.stringify(result);
    result = CryptoJS.enc.Utf8.parse(result);
    let dataVal = CryptoJS.enc.Base64.stringify(result)
    return dataVal
  }

}
