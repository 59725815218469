<div class="pt-4 pb-4">
    <p class="text-center search-image">
        <img src="../../../../assets/images/by_phone_number.svg" />
    </p>
    <h2 class="reg-heading text-center">Buy Phone Number</h2>
    <p class=" text-center coupon-text">SMS messaging requires you register a phone number with the carrier to send and receive sms messages. The carrier charges $5.00 a month and a $.007 per text fee. This will be charged to your account after the free trial.</p>
    <div class="searchresult  h-100 vertical-scrollbale">
        <div class="white-box p-3 mb-4">
            <form #searchNumber="ngForm" name="searchNumber">
                <div class="row">
                    <div class="col-lg-5 col-md-12 col-sm-6">
                        <div class="form-group widget-form-group">
                            <label for=""> Search By
                            <a  tooltip="Search By Area Code" placement="bottom">
                                <i class="material-icons">
                                    info
                                </i>
                            </a>
                            </label>
                            <div class="btn-group d-flex" dropdown>
                            <div aria-controls="dropdown-animated" dropdownToggle
                                class="input-group custom-select-value form-control">
                                {{selectedValue}}</div>
                            <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu"
                                aria-labelledby="button-animated" required>
                                <li role="menuitem">
                                    <a class="dropdown-item" 
                                        (click)="selectItem('Area Code')">Area Code </a></li>
                                <li role="menuitem">
                                    <a class="dropdown-item" 
                                        (click)="selectItem('Number')">Number</a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5  col-md-12 col-sm-6">
                        <div class="form-group widget-form-group">
                            <label for="">Search By {{selectedValue}}
                                <i class="text-danger">*</i>
                            </label>
                            <div class="input-group">
                                <input type="text" class="form-control"  name="searchNumberVal" placeholder="Search By Digit" #searchNumberModel="ngModel"
                                    required [(ngModel)]="searchNumberVal" [ngClass]="{'is-invalid': searchNumberModel.invalid && (searchNumberModel.dirty || searchNumberModel.touched)}"/>
                                <div *ngIf="searchNumberModel.invalid && (searchNumberModel.dirty || searchNumberModel.touched)"
                                    class="invalid-feedback d-block">
                                    <div *ngIf="searchNumberModel.errors.required">
                                       Search by is required.
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">
                        <button type="submit" *ngIf="stepNo<3" class="btn btn-primary submit-btn mt-30 small search-btn" (click)="searchData()" [disabled]="serverBusy || searchNumber.invalid">
                            <i class="material-icons">
                                search
                            </i>  Search
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="white-box p-3" *ngIf="numberList.length>0 && stepNo<3">
            <h5 class="widget-sub-heading">Search Result</h5>
            <div class="table-wrap">
                <table class="table table-striped visitor-table">
                    <thead>
                        <tr>
                            <th class="text-right width-85">
                                #
                            </th>
                            <th class="">Phone Number</th>
                            <th>Friendly Name</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of numberList;let i=index">
                            <td class="text-right width-75 clearfix">
                                <span
                                    class="ml-3 label-align">{{i+1}}</span>
                            </td>

                            <td class="">{{item.phoneNumber}}</td>
                            <td class="">
                                {{item.friendlyName}}
                            </td>
                            <td class="max-with-75 text-center">
                                <button class="btn btn-link tag-default  text-center" (click)=" openModal(item,forwardModel)">
                                    <i class="material-icons">
                                        shopping_cart
                                     </i>  Buy
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table-pagination-part">
                <div class="row align-items-center justify-content-between">
                    <div class="col-12 col-sm-6">
                        <pagination [totalItems]="totalItems" [(ngModel)]="currentPage"
                    class="table-pagination mb-0"></pagination>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="d-flex align-items-center show-rows mt-sm-0 mt-2 justify-content-end">
                            <p class="m-0 result-count">Show Rows</p>
                            <select name="itemsPerPage">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr class="thereHr mt-4" />
    <div class="clearfix">
        <button type="button" class="btn submit-btn mt-2 small previous-btn float-left" (click)="preStep()">
            Previous
        </button>
        <button type="submit" *ngIf="stepNo<3"  (click)="redirectToNext()"  class="btn btn-primary submit-btn mt-2 small theme-btn-shadow float-right">
            Next
        </button>
        <button type="submit" (click)="redirectToNext()" *ngIf="stepNo>=3" class="btn btn-primary submit-btn mt-2 small theme-btn-shadow float-right">
            Next
         </button>
    </div>

</div>


<ng-template #forwardModel>
    <div class="modal-header pt-2 pb-2 pr-3 pl-3 align-items-center">
       <h4 class="modal-title">Buy This Number ?</h4>
       <button type="button" class="btn btn-primary cancel-btn" aria-label="Close" (click)="modalRef.hide()">
           <span class="material-icons">
               close
           </span>
       </button>
   </div>
    <div class="modal-body p-3">
       <div class="container-fluid">
           <div class="text-center p-3 buyNumberImage">
               <img src="../../../../assets/images/buy-number-payment.svg" />
           </div>
           <h5 class="widget-sub-heading">Buy This Number ?</h5>
        <div class="white-box p-3 shadow-none">
            <form #buyNumberForm="ngForm" name="buyNumberForm">
                <div class="row">
                   <div class="col-md-6">
                      <div class="form-group">
                         <div class="form-group widget-form-group icon-field">
                            <label for="">Phone  Number</label>
                            <div class="input-group">
                               <input type="text" class="form-control" disabled name="friendlyName" [(ngModel)]="friendlyName">
                               <div class="input-group-prepend">
                                  <span class="std-code">
                                     <img src="../../../../../assets/images/usa-country.png" />
                                     +1
                                     <span class="material-icons dropdown-icon">
                                        arrow_drop_down
                                     </span>
                                  </span>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="col-md-6">
                      <div class="form-group widget-form-group icon-field">
                         <label for="">Amount (per Month)
                            <a  tooltip="Amount (per Month)" placement="bottom">
                               <i class="material-icons">
                                  info
                               </i>
                            </a>
                         </label>
                         <div class="input-group">
                            <input type="text" class="form-control" disabled placeholder="Amount" name="amount" [(ngModel)]="amount"  />
                            <div class="input-group-prepend">
                               <span class="input-group-text add-amount">
                               </span>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="col-md-12">
                      <div class="form-group widget-form-group icon-field">
                         <label for="">Forwarding Number
                            <a  tooltip="Phone Number to forward voice calls on this number too" placement="bottom">
                                <i class="material-icons">
                                   info
                                </i>
                             </a>
                         </label>
                         <div class="input-group">
                            <input type="text" class="form-control" #callForwardingModel="ngModel" [ngClass]="{'is-invalid': callForwardingModel.invalid && (callForwardingModel.dirty || callForwardingModel.touched)}" minlength="10" appPhoneMask maxlength="15" name="callForwardingNumber" [(ngModel)]="callForwardingNumber">
                            <div class="input-group-prepend">
                               <span class="std-code">
                                  <img src="../../../../../assets/images/usa-country.png" />
                                  +1
                                  <span class="material-icons dropdown-icon">
                                     arrow_drop_down
                                  </span>
                               </span>
                            </div>
                         </div>
                         <div *ngIf="callForwardingModel.invalid && (callForwardingModel.dirty || callForwardingModel.touched)"
                            class="invalid-feedback d-block">
                            <div *ngIf="callForwardingModel.errors.pattern">
                               Please enter number only.
                            </div>
                            <div *ngIf="callForwardingModel.errors.minlength">
                              Please enter valid number.
                           </div>
                            </div>
                      </div>
                   </div>
                </div>
                
                
             </form>
        </div>
        <div class="text-center mt-4 mb-3">
            <button type="submit" class="btn btn-primary submit-btn small theme-btn-shadow" (click)="validNumberBuy()" [disabled]="serverBusy || buyNumberForm.invalid">
               <i class="material-icons">
                   done
               </i>  Submit
           </button>
         </div>
       </div>
       
    </div>
  </ng-template>
  <div class="loaderDiv" *ngIf="serverBusy">
    <div class="loader-div">
        <div class="circle circle-1"></div>
        <div class="circle circle-2"></div>
    </div>
</div>