import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  RecaptchaModule,
  RecaptchaFormsModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
} from 'ng-recaptcha';

// components
import { AuthComponent } from './login/auth.component';
import { LogoutComponent } from './login/logout.component';

// Service
import { JwtService } from './jwt.service';
import { UserService } from './user.service';
import { SharedModule, AuthGuard, NoAuthGuard } from '../shared';
import { RegistrationComponent } from './registration/registration.component';
import { ArchwizardModule } from 'angular-archwizard';
import { BasicInfoComponent } from './registration/basic-info/basic-info.component';
import { PricingComponent } from './registration/pricing/pricing.component';
import { SearchNumberComponent } from './registration/search-number/search-number.component';
import { AddStaffComponent } from './registration/add-staff/add-staff.component';
import { CreateWidgetComponent } from './registration/create-widget/create-widget.component';
import { InstallationComponent } from './registration/installation/installation.component';
import { NgxBootstrapComponentModuleModule } from '../ngx-bootstrap-component-module.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AgmCoreModule } from '@agm/core';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VideoplayerComponent } from './videoplayer/videoplayer.component';

// Directives
const authRouting: ModuleWithProviders = RouterModule.forChild([
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: AuthComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'forcelogin/:forceLoginCode',
    component: AuthComponent,
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'setpassword/:token',
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'resetpassword/:token',
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'videoplayer/:vurl',
    component: VideoplayerComponent,
  },
]);

@NgModule({
  imports: [
    FormsModule,
    NgxBootstrapComponentModuleModule,
    NgMultiSelectDropDownModule.forRoot(),
    ArchwizardModule,
    ReactiveFormsModule,
    authRouting,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD6wIC7ImJyv-AvpH2WUgcxLS8oq3aBCVE',
      libraries: ['places'],
    }),
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  declarations: [
    AuthComponent,
    LogoutComponent,
    RegistrationComponent,
    BasicInfoComponent,
    PricingComponent,
    SearchNumberComponent,
    AddStaffComponent,
    CreateWidgetComponent,
    InstallationComponent,
    ForgotPasswordComponent,
    VideoplayerComponent,
  ],

  providers: [
    NoAuthGuard,
    UserService,
    JwtService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LdahYQcAAAAANXQdDgiSU4HFa8kNx_apn8nGGWK',
      } as RecaptchaSettings,
    },
  ],
  exports: [],
})
export class AuthModule {
  constructor() {
  }
}
