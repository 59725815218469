import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../user.service';
import { ToastrService } from 'ngx-toastr';
import { MessagingService } from 'src/app/service/messaging.service';

@Component({
  template: ''
})

export class LogoutComponent implements OnInit {
  browserLat: any;
  browserLng: any;
  constructor(
    private messagingService : MessagingService,
    private router: Router,private toastr :ToastrService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.messagingService.disconnect()
    this.userService.logout().subscribe(data=>{
      this.userService.purgeAuth();
      this.router.navigate(['/login']);
    },error=>{
      this.toastr.error(error)
    })
  }

}
