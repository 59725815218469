<div class="registration-page">
  <div class="reg-logo-wrap">
    <img class="reg-logo" src="../../../assets/images/logo.png" alt="Site logo" />
  </div>
  <aw-wizard [navBarLayout]="'large-empty-symbols'" [navBarLocation]="'left'" [awNavigationMode]
    [navigateForward]="'allow'" [navigateBackward]="'allow'" class="registration-Step">
    <aw-wizard-step stepTitle="Registration" [canEnter]="activeTab[0].active" (stepEnter)="enterStep($event)"
      [navigationSymbol]="{ symbol: 'assignment', fontFamily: 'Material Icons' }">
      <div class="main-wrap h-100 p-3  vertical-scrollbale">
        <app-basic-info></app-basic-info>
        <button type="button" class="btn btn-secondary" id="next_1" [hidden]="true" awNextStep>Next</button>
      </div>
    </aw-wizard-step>
    <aw-wizard-step stepTitle="ChatRaven Pricing" [canEnter]="activeTab[1].active" (stepEnter)="enterStep($event)"
      [navigationSymbol]="{ symbol: 'local_offer', fontFamily: 'Material Icons' }">
      <div class="main-wrap h-100 p-3  pricing-height vertical-scrollbale">
        <app-pricing *ngIf="activeTab[1].active"></app-pricing>
        <button type="button" class="btn btn-secondary" id="next_2" [hidden]="true" awNextStep>Next</button>
        <button type="button" class="btn btn-secondary" id="pre_2" [hidden]="true" awPreviousStep>Pre</button>
      </div>
    </aw-wizard-step>
    <aw-wizard-step stepTitle="Search" [canEnter]="activeTab[2].active" (stepEnter)="enterStep($event)"
      [navigationSymbol]="{  symbol: 'search', fontFamily: 'Material Icons' }">
      <div class="main-wrap h-100 p-3  vertical-scrollbale">
        <app-search-number *ngIf="activeTab[2].active"></app-search-number>
        <button type="button" class="btn btn-secondary" id="next_3" [hidden]="true" awNextStep>Next</button>
        <button type="button" class="btn btn-secondary" id="pre_3" [hidden]="true" awPreviousStep>Pre</button>
      </div>
    </aw-wizard-step>
    <aw-wizard-step stepTitle="Create Staff" [canEnter]="activeTab[3].active" (stepEnter)="enterStep($event)"
      [navigationSymbol]="{  symbol: 'groups', fontFamily: 'Material Icons'  }">
      <div class="main-wrap h-100 p-3  vertical-scrollbale">
        <app-add-staff *ngIf="activeTab[3].active"></app-add-staff>
        <button type="button" class="btn btn-secondary" id="next_4" [hidden]="true" awNextStep>Next</button>
        <button type="button" class="btn btn-secondary" id="pre_4" [hidden]="true" awPreviousStep>Pre</button>
      </div>
    </aw-wizard-step>
    <aw-wizard-step stepTitle="Create Your Website Widget" (stepEnter)="enterStep($event)"
      [canEnter]="activeTab[4].active" [navigationSymbol]="{ symbol: 'table_chart', fontFamily: 'Material Icons' }">
      <div class="main-wrap h-100 p-3  vertical-scrollbale">
        <app-create-widget *ngIf="activeTab[4].active"></app-create-widget>
        <button type="button" class="btn btn-secondary" id="next_5" [hidden]="true" awNextStep>Next</button>
        <button type="button" class="btn btn-secondary" id="pre_5" [hidden]="true" awPreviousStep>Pre</button>
      </div>
    </aw-wizard-step>
    <aw-wizard-step stepTitle="Installation" [canEnter]="activeTab[5].active"
      [navigationSymbol]="{ symbol: 'settings', fontFamily: 'Material Icons'  }">
      <div class="main-wrap h-100 p-3  vertical-scrollbale">
        <app-installation *ngIf="activeTab[5].active"></app-installation>
      </div>
    </aw-wizard-step>
  </aw-wizard>
</div>
