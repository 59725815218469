<div class="pt-4 pb-4">
  <h2 class="m-0 mb-4 block-heading">Membership Plan</h2>
  <p class="plan-dic">These are the available subscription options after your free trial</p>
  <div class="row membership-plan-row">
    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4" (click)="setPlan(10)">
      <div class="card membershipl-plan-block basic h-100" [ngClass]="plan=='basic'?'active':''">
        <div class="card-header">
          <h3 class="membership-plan-title">Basic</h3>
          <div class="triangle"></div>
        </div>
        <div class="card-body">
          <div class="img-outer  text-center">
            <img src="../../../../assets/images/plan-img.png" />
          </div>
          <div class="price-block  text-center">

            <h3 class="price mb-0">$149<small>/Mo</small></h3>
            <p class="plan-details">Unlimited*</p>
          </div>
          <ul class="plan-feature">
            <li> <img class="mr-2" src="../../../../assets/images/check.png" />Chat Widget </li>
            <li> <img class="mr-2" src="../../../../assets/images/check.png" />Reviews</li>
            <li> <img class="mr-2" src="../../../../assets/images/check.png" />SMS Marketing Campaigns</li>
            <li> <img class="mr-2" src="../../../../assets/images/remove.png" />Virtual Consults</li>

          </ul>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4" (click)="setPlan(11)">
      <div class="card membershipl-plan-block basic h-100" [ngClass]="plan=='pro'?'active':''">
        <div class="card-header">
          <h3 class="membership-plan-title">Pro</h3>
          <div class="triangle"></div>
        </div>
        <div class="card-body">
          <div class="img-outer  text-center">
            <img src="../../../../assets/images/basic-img.png" />
          </div>
          <div class="price-block  text-center">

            <h3 class="price mb-0">$199<small>/Mo</small></h3>
            <p class="plan-details">Unlimited*</p>
          </div>
          <ul class="plan-feature">
            <li> <img class="mr-2" src="../../../../assets/images/check.png" />Chat Widget </li>
            <li> <img class="mr-2" src="../../../../assets/images/check.png" />Reviews</li>
            <li> <img class="mr-2" src="../../../../assets/images/check.png" />SMS Marketing Campaigns</li>
            <li> <img class="mr-2" src="../../../../assets/images/check.png" />Virtual Consults</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
      <div class="card membershipl-plan-block basic h-100 demo-card">
        <div class="card-body">
          <div class="card-text">
            <h3>
              Enterprise <br>
            </h3>
            <p>Contact us if you have multiple websites and locations, need to manage them all in one easy to use
              dashboard.
            </p>
          </div>

          <div class="img-outer  text-center">
            <img src="../../../../assets/images/plan-card.png" />
          </div>
        </div>
      </div>
    </div>
</div>

<hr class="thereHr mt-4" />
<div class="clearfix">
  <button type="button" class="btn submit-btn mt-2 small previous-btn float-left" (click)="preStep()">
    Previous
  </button>
  <button type="submit" *ngIf="stepNo<2" class="btn btn-primary submit-btn mt-2 small theme-btn-shadow float-right"
    (click)="submitLevel()">
    Start Trial Now
  </button>
  <button type="submit" (click)="redirectToNext()" *ngIf="stepNo>=2"
    class="btn btn-primary submit-btn mt-2 small theme-btn-shadow float-right">
    Start Trial Now
  </button>
</div>
</div>
<div class="loaderDiv" *ngIf="serverBusy">
  <div class="loader-div">
    <div class="circle circle-1"></div>
    <div class="circle circle-2"></div>
  </div>
</div>