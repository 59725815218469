<div class="container-fluid login-bg-outer" *ngIf="loginShow" >
   <div class="row">
      <div class="col-md-12 login-left">
         <a class="login-logo">
            <img src="../../../assets/images/logo.png" alt="logo" class="ml-5 mt-3"/>
         </a>
      </div>
   </div>
   <div class="row">
      <div class="col-lg-5 col-md-12 d-flex align-items-center login-form-outer">
         <div class="d-flex align-items-center login-left ">
            <!-- <a class="login-logo">
               <img src="../../../assets/images/logo.png" alt="logo" />
            </a> -->
            <div class="row w-100 bg-white border-r m-2">
               <div class="col-lg-12 col-md-10">
                  <h2 class="font-weight-bold mb-3 text-center login-heading">
                     Sign into
                     your account
                  </h2>
                  <p  class=" text-center mb-5 login-para">A new and better way to chat, engage and retain customers
                  </p>
                  <div class="login-form-container">
                     <form class="" [formGroup]="authForm" autocomplete="off" novalidate="">
                        <div class="form-group form-field-wrap">
                           <input type="text" class="form-control pl-0 f-w-500 rounded-0" id="exampleInputEmail1"
                              placeholder="" name="email" formControlName="email" required />
                           <label class="f-w-500 m-0 position-absolute" for="exampleInputEmail1"> Enter Username / Email
                              ID </label>
                        </div>
                        <div class="form-group form-field-wrap hasview-btn">
                           <button class="view-btn view-password" type="button" onclick="viewPassword(this)">
                              <i class="fa fa-eye" aria-hidden="true"></i>
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                           </button>
                           <input type="password" class="form-control pl-0 f-w-500 rounded-0" id="exampleInputPassword1"
                              placeholder="" formControlName="password" type="password" name="password" required />
                           <label class="f-w-500 m-0 position-absolute" for="exampleInputPassword1">Password </label>
                        </div>
                        <!-- <p class="mt-0 text-right forgot-link mb-3 mob-none">
                           <a class="f-w-500" routerLink="/forgotpassword">Forgot password?</a>
                        </p>
                        <div class="custom-control custom-checkbox mb-3 mob-none">
                           <input type="checkbox" class="custom-control-input" id="customCheck" name="example1">
                           <label class="custom-control-label" for="customCheck"> Remember Me</label>
                        </div> -->
                        <div class="remember-outer">
                           <div class="remember-flex mb-3 d-flex justify-content-between align-items-center flex-wrap">
                           <div class="custom-control custom-checkbox ">
                              <input type="checkbox" class="custom-control-input" id="customCheck" name="example1">
                              <label class="custom-control-label" for="customCheck"> Remember Me</label>
                           </div>
                           <div><p class="my-0 text-right forgot-link mb-1">
                              <a class="f-w-500" routerLink="/forgotpassword">Forgot password?</a>
                           </p></div>
                         </div>
                        </div>
                        <button type="button" class="btn btn-primary submit-btn  mb-3" (click)="userLogin()"
                           [disabled]="isSubmitting">
                           Sign in
                           <span *ngIf="isSubmitting"><i class="fa fa-circle-o-notch fa-spin"
                                 aria-hidden="true"></i></span>
                        </button>
                        <div class="sign-info">
                           <span class="">Don't have an account? <a routerLink="/registration">Sign
                                 up</a></span>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- <div class="col-xl-8 col-lg-7 col-md-7">
         <div class="d-flex align-items-center login-right-content ">
            <div class="w-75 right-inner">
               <h2>A new and better way to chat, engage and retain customers
               </h2>
               <p class="mb-0 mt-3">With our sms chatting, virtual consults, virtual waiting room, and texting campaigns.
               </p>
            </div>
         </div>
      </div> -->
   </div>
   <!-- <img src="../../../assets/images/login-bg.png" class="login-bg" alt="logo" /> -->
</div>