import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxBootstrapComponentModuleModule } from '../ngx-bootstrap-component-module.module';
import { ShowAuthedDirective } from './directives/show-auth.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { PhoneFormatePipePipe } from './pipes/phone-formate-pipe.pipe';
import {AutosizeModule} from 'ngx-autosize';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgxBootstrapComponentModuleModule,
    AutosizeModule
  ],
  declarations: [
    ShowAuthedDirective,
    PhoneMaskDirective,
    PhoneFormatePipePipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ShowAuthedDirective,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    PhoneMaskDirective,
    PhoneFormatePipePipe,
    NgxBootstrapComponentModuleModule,
    AutosizeModule
  ]

})
export class SharedModule {}
