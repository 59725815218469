<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-lg-10">
      <h5 class="mt-2 block-heading mb-4 mt-4"></h5>
      <div class="rounded" *ngIf="frameShow">
        <form name="vrEnable" #vrWait="ngForm">
          <div class="row mb-4">
            <div class="col-md-12">
              <div class="form-group virtual-wait-frame">
                <label for=""
                  >Hello, Give Chat Raven A Try Right Here. Are You Looking To
                  Get More Customers From Your Existing Website Traffic ?
                  <span class="text-danger">*</span>
                </label>
                <textarea
                  [(ngModel)]="frameModelVal.message"
                  required
                  name="ravenMsg"
                  #ravenMsg="ngModel"
                  class="form-control"
                  rows="7"
                  [ngClass]="{
                    'is-invalid':
                      ravenMsg.invalid && (ravenMsg.dirty || ravenMsg.touched)
                  }"
                ></textarea>
                <div
                  *ngIf="
                    ravenMsg.invalid && (ravenMsg.dirty || ravenMsg.touched)
                  "
                  class="invalid-feedback d-block"
                >
                  <div *ngIf="ravenMsg.errors.required">Required Field.</div>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group virtual-wait-frame">
                <label for=""
                  >Please Enter Your Name <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  required
                  #ravenName="ngModel"
                  [ngClass]="{
                    'is-invalid':
                      ravenName.invalid &&
                      (ravenName.dirty || ravenName.touched)
                  }"
                  name="name"
                  [(ngModel)]="frameModelVal.name"
                />
                <div
                  *ngIf="
                    ravenName.invalid && (ravenName.dirty || ravenName.touched)
                  "
                  class="invalid-feedback d-block"
                >
                  <div *ngIf="ravenName.errors.required">Required Field.</div>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div
                class="form-group icon-field virtual-wait-frame"
                *ngIf="showPhoneNo"
              >
                <label for=""
                  >Please Enter Your Phone Number
                  <span class="text-danger">*</span></label
                >
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    required
                    minlength="10"
                    #ravenNumber="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        ravenNumber.invalid &&
                        (ravenNumber.dirty || ravenNumber.touched)
                    }"
                    appPhoneMask
                    maxlength="15"
                    name="phoneNumber"
                    [(ngModel)]="frameModelVal.phone"
                  />
                  <div class="input-group-prepend">
                    <span class="std-code">
                      <img src="../../../../../assets/images/usa-country.png" />
                      +1
                      <span class="material-icons dropdown-icon">
                        arrow_drop_down
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="
                    ravenNumber.invalid &&
                    (ravenNumber.dirty || ravenNumber.touched)
                  "
                  class="invalid-feedback d-block"
                >
                  <div *ngIf="ravenNumber.errors.required">Required Field.</div>
                </div>
              </div>
            </div>

            <div class="col-md-12 my-4">
              <div class="text-center">
                <button
                  class="btn btn-primary submit-btn small"
                  [disabled]="vrWait.invalid"
                  (click)="saveVsData()"
                >
                  <i class="material-icons"> done </i>Submit Details
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="success-message" *ngIf="!frameShow">
        <div class="white-box w-100">
            <p class="text-center mb-2 p-3">
                <img src="../../../assets/images/submission_thanks.svg" class="success-img" />
            </p>
            <h6 class="text-center f-w-500 mb-4">
                {{successMessage}}
            </h6>
            <!-- <p class="text-center m-0"><button type="button" class="btn btn-primary submit-btn small theme-btn-shadow mb-3 min-widthbtn"
                    (click)="closeTab()">
                    <i class="material-icons" aria-hidden="true">done</i> Done </button>
            </p> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loaderDiv" *ngIf="serverBusy">
  <div class="loader-div">
    <div class="circle circle-1"></div>
    <div class="circle circle-2"></div>
  </div>
</div>

