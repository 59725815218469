import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpEvent,
  HttpInterceptor,
  HttpEventType
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EncryptionService } from '../services/encyption.service';

@Injectable()
export class EncryptionInterceptor implements HttpInterceptor {

  constructor(
    private encryptionService: EncryptionService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const body = request.body;

    let modifyRequest;
    let environment  = window.location.hostname
    if (body instanceof FormData) {
      modifyRequest = request;
    } else {
      // add encrypted data in body
      modifyRequest = request.clone({
        body: {
          payload: this.encryptionService.encodeAPIReqestData(body),
          actualData :  environment == 'localhost' ? body : ""
        }
      });
    }

    return next.handle(modifyRequest).pipe(
      // filter only HttpResponse and json response
      filter(event => event.type === HttpEventType.Response),
      map((event: HttpResponse<any>) => {
        if ("payload" in event.body) {
          let payloadData = this.encryptionService.decodeAPIResponse(event.body.payload);
          const modEvent = event.clone({ body: payloadData });
          return modEvent;
        }
        return event;
      })
    );
  }
}
