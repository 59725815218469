export class globleVariable {
  "urlCheck": number;
  "loginFormShow": boolean = true;
  "forceToken": string = ""
}
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'auth-page',
  templateUrl: './auth.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./auth.style.scss']
})
export class AuthComponent implements OnInit {
  globleVariable = new globleVariable()
  authType: String = '';
  title: String = '';
  isSubmitting = false;
  authForm: FormGroup;
  disableButton = false;
  forceLoginCode = ""
  loginShow = false
  formErrors = {
    'email': '',
    'password': ''
  };

  validationMessages = {
    'email': {
      'required': 'This field is required.',
      'pattern': 'Please enter valid email',
    },

    'password': {
      'required': 'This field is required.',
      'maxlength': 'This field should contain maximum 20 characters',
      'minlength': 'This field should contain minimum 8 characters'
    },
  };
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService, public toastr: ToastrService,
    private fb: FormBuilder,
  ) {
    this.authForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(2)]],
      forceLogin: ['']
    }, { updateOn: 'blur' });
  }

  ngOnInit() {
    this.isSubmitting = true
    if (this.route.snapshot.params.forceLoginCode) {
      this.forceLoginCode = ""
      this.loginShow = false
      if (window.localStorage['jwtToken']) {
        this.userService.logout().subscribe(data => {
          this.userService.purgeAuth();
          this.forceLoginCode = this.route.snapshot.params.forceLoginCode;
          this.userLogin()
        }, error => {
          this.toastr.error(error)
        })
      } else {
        this.forceLoginCode = this.route.snapshot.params.forceLoginCode;
        this.userLogin()
      }
    } else {
      this.isSubmitting = false
      this.loginShow = true
    }
  }

  userLogin() {
    this.isSubmitting = true
    let inputParam = {
      "email": this.authForm.value.email,
      "password": this.authForm.value.password
    }
    if (this.forceLoginCode) {
      inputParam["login"] = this.forceLoginCode
    }
    this.userService.userSignIn(inputParam, this.forceLoginCode).subscribe(data => {
      this.getCamp()
      if (data.alertMessage) {
        this.toastr.warning(data.alertMessage);
      }
    }, error => {
      this.isSubmitting = false
      this.toastr.error(error)
    })
  }

  getMsgCount() {
    this.userService.getCount().subscribe(data => {
      let url = data["activePath"]
      this.router.navigate([url]);
      this.isSubmitting = false
    }, error => {
      this.isSubmitting = false
      this.toastr.error(error)
    })
  }

  getCamp() {
    this.userService.getcamp().subscribe(data => {
      if (data.status) {
        window.localStorage["campRoleType"] = "campaignRole"
      } else {
        window.localStorage["campRoleType"] = "notCampaignRole"
      }
      this.getMsgCount()
    })
  }
}
