import {
  Component,
  OnInit,
  Directive,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { basicInfo } from '../registration';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { triggerSingleDropdown } from 'src/app/app.component';
import { RecaptchaErrorParameters } from 'ng-recaptcha';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss'],
})
export class BasicInfoComponent implements OnInit {
  basicInfoModel = new basicInfo();
  confirmPassword = '';
  confirmEmail = '';
  stepNo = 0;
  serverBusy = false;
  selectedValue = '--Select--';
  timeZoneData = [];
  private unsubscribeAll: Subject<any>;
  constructor(private toastr: ToastrService, private userService: UserService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.timeZoneList();
    this.userService.preStepChangeData
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((data) => {
        if (data) {
          this.stepNo = data;
        }
      });
    if (window.localStorage['basicInfoModel']) {
      this.basicInfoModel = JSON.parse(window.localStorage['basicInfoModel']);
      this.confirmEmail = this.basicInfoModel.email;
      this.confirmPassword = this.basicInfoModel.password;
      if (this.basicInfoModel.timezone) {
        let selectedVal = this.timeZoneData.find(
          (v) => v.id == this.basicInfoModel.timezone
        );
        if (selectedVal) {
          this.selectedValue = selectedVal.name;
        }
      }
    }
  }

  resolved(captchaResponse: string) {
    this.basicInfoModel.captcha = captchaResponse;
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  selectItem(item) {
    this.selectedValue = item.name;
    this.basicInfoModel.timezone = item.id;
  }

  dropdownClicked(dropId: string) {
    triggerSingleDropdown(dropId);
  }

  timeZoneList() {
    this.userService.getZoneTime().subscribe(
      (data) => {
        this.timeZoneData = data.result;
        if (this.basicInfoModel.timezone) {
          let selectedVal = this.timeZoneData.find(
            (v) => v.id == this.basicInfoModel.timezone
          );
          if (selectedVal) {
            this.selectedValue = selectedVal.name;
          }
        }
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  }

  submitLevel() {
    this.serverBusy = true;
    let passCom = this.comparePass();
    let emailCom = this.compareEmail();
    if (!passCom) {
      this.serverBusy = false;
      return false;
    }
    if (!emailCom) {
      this.serverBusy = false;
      return false;
    }
    this.basicInfoModel.phone_number = this.userService.phoneNumberset(
      this.basicInfoModel.phone_number
    );
    this.userService.saveLevelOne(this.basicInfoModel).subscribe(
      (data) => {
        window.localStorage['basicInfoModel'] = JSON.stringify(
          this.basicInfoModel
        );
        this.loginUser();
        this.toastr.success(data.message);
      },
      (err) => {
        this.serverBusy = false;
        this.toastr.error(err);
      }
    );
  }

  comparePass() {
    if (
      this.basicInfoModel.password &&
      this.confirmPassword &&
      this.basicInfoModel.password.trim().toString() !=
        this.confirmPassword.trim().toString()
    ) {
      this.toastr.error('Passwords must match.');
      return false;
    } else {
      return true;
    }
  }

  compareEmail() {
    if (
      this.basicInfoModel.email &&
      this.confirmEmail &&
      this.basicInfoModel.email.trim().toString() !=
        this.confirmEmail.trim().toString()
    ) {
      this.toastr.error('Emails must match.');
      return false;
    } else {
      return true;
    }
  }

  availablityCheck() {
    if (this.basicInfoModel.email) {
      let inputParam = {
        email: this.basicInfoModel.email,
      };
      this.userService.emailCheck(inputParam).subscribe(
        (data) => {
          this.toastr.success(data.message);
          this.compareEmail();
        },
        (err) => {
          this.toastr.error(err);
        }
      );
    }
  }

  loginUser() {
    let inputParam = {
      email: this.basicInfoModel.email,
      password: this.basicInfoModel.password,
    };
    this.userService.newUserSignIn(inputParam).subscribe(
      (data) => {
        this.userService.onNextSteoData(1, 'next');
        this.serverBusy = false;
      },
      (error) => {
        this.serverBusy = false;
        this.toastr.error(error);
      }
    );
  }

  redirectToNext() {
    this.userService.onNextSteoData(1, 'next');
  }
}

@Directive({ selector: '[preventCutCopyPaste]' })
export class CopyDirective {
  constructor(el: ElementRef, renderer: Renderer2) {
    var events = 'cut copy paste';
    events.split(' ').forEach((e) =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
      })
    );
  }
}
