import { NgModule, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, AsyncPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AuthModule } from './authentication/auth.module';
import { ToastrModule } from 'ngx-toastr';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MessagingService } from './service/messaging.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { EncryptionInterceptor } from './shared/interceptor/encryption.interceptor';
import {
  ApiService,
  AuthGuard,
  SharedModule,
  CommanApiService,
  HeaderComponent,
  FooterComponent,
} from './shared';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './http.interceptor';
// RECOMMENDED
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import {
  HammerGestureConfig,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { VirtualWaitFrameComponent } from './virtual-waiting-room/virtual-wait-frame/virtual-wait-frame.component';
// custom configuration Hammerjs
@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // I will only use the swap gesture so
    // I will deactivate the others to avoid overlaps
    pinch: { enable: true },
    rotate: { enable: true },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    VirtualWaitFrameComponent,
  ],
  imports: [
    BrowserModule, //core
    CommonModule, //core
    BrowserAnimationsModule, //core
    HttpClientModule, //core
    AuthModule,
    // HomeModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(), // ToastrModule added
    SharedModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    HammerModule,
    ReactiveFormsModule,
    // ServiceWorkerModule.register('firebase-messaging-sw.js')
    AppRoutingModule,
  ],

  providers: [
    ApiService, CommanApiService, MessagingService, AsyncPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EncryptionInterceptor, multi: true },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
