import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders ,HttpParams ,HttpErrorResponse} from '@angular/common/http';

import { JwtService } from '../../authentication/jwt.service';
// import { SessionService } from '../../authentication/session.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    // private sessionService: SessionService
  ) {}

  private setHeaders(headers: any = {}): HttpHeaders {
    headers['Accept'] = 'application/json';
    if (this.jwtService.getToken()) {
      headers['Authorization'] = `${this.jwtService.getToken()}`;
    }
    return new HttpHeaders(headers);
  }

  private formatErrors(error: any) {
       if (error.status === 500) {
          return throwError(error.status);
      }
      else if (error.status === 400) {
          return throwError(error.error);
      }
      else if (error.status === 409) {
          return throwError(error);
      }
      else if (error.status === 406) {
          return throwError(error);
      }
      else if (error.status == 401) {
         localStorage.clear();
         window.location.reload();
         return throwError(error);
      } else if (error.status == 0){
        return throwError(error.statusText);
      }
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.api_url}${path}`, { headers: this.setHeaders({}), params })
    .pipe(
      catchError(this.formatErrors)
      );
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.api_url}${path}`,
      body,
      { headers: this.setHeaders({}) }
    )
    .pipe(
      catchError(this.formatErrors)
      );
  }

 post(path: string, body: Object = {}, headers: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.api_url}${path}`,
      body,
      { headers: this.setHeaders(headers) }
      ).pipe(
        catchError(this.formatErrors)
        );
  }

  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.api_url}${path}`,
      { headers: this.setHeaders({}) }
    ).pipe(
      catchError(this.formatErrors)
      );
  }

  getImageFile(path: string, body: Object = {}, headers: Object = {}): Observable<any> {
    return this.http.post(`${environment.api_url}${path}`,body, {responseType: "arraybuffer" ,headers: this.setHeaders(headers)})
    .pipe(
      tap( // Log the result or error
        data =>{
          return data
        },
        error => {
          catchError(this.formatErrors)
        }
     ));
  }
}
