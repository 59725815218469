import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VirtualWaitingRoomService } from '../virtual-waiting-room.service';
import { frameModel } from '../virtualWaitingRoomVar';

@Component({
  selector: 'app-virtual-wait-frame',
  templateUrl: './virtual-wait-frame.component.html',
  styleUrls: ['./virtual-wait-frame.component.scss']
})
export class VirtualWaitFrameComponent implements OnInit {

  frameModelVal = new frameModel()
  showPhoneNo = true
  serverBusy = false
  frameShow = true
  successMessage = ""
  constructor(private route: ActivatedRoute,private http: HttpClient,private toastr :ToastrService,private virtualWaitingRoomService : VirtualWaitingRoomService) { }

  ngOnInit(): void {
    this.http.get<{ip:string}>('https://jsonip.com')
    .subscribe( data => {
      this.frameModelVal.ip_address = data.ip;
    })
    this.route.params.subscribe(params => {
      this.frameShow = true
      this.successMessage = ""
      if (params["api_token"]) {
        this.frameModelVal.api_token = params["api_token"]
      }
    })
  }

  saveVsData(){
    this.serverBusy = true
    this.virtualWaitingRoomService.frameData(this.frameModelVal).subscribe(data=>{
      this.successMessage = data.resmessage
      this.frameShow = false
      this.serverBusy = false
    },err=>{
      this.showPhoneNo = false
      setTimeout(() => {
        this.showPhoneNo = true
      }, 5);
      this.serverBusy = false
      this.toastr.error(err)
    })
  }

}
