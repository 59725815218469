import { Component, OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../user.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  monthList = this.userService.monthListVal()
  yearList = []
  serverBusy = false
  applyCode = "";
  modalRef: BsModalRef;
  SelectedMonth = "Month"
  selectedYear = "Year"
  cvvNumber = ""
  name = ""
  cardNumber = ""
  stepNo = 0
  validCode = false
  enable_virtual = 1
  activePlanId = ""
  allPlan = []
  prePlan = 'pro'
  demo = false
  private unsubscribeAll: Subject<any>;
  plan: string = "basic";
  constructor(private changeDetector: ChangeDetectorRef, private modalService: BsModalService, private toastr: ToastrService, private userService: UserService) {
    this.unsubscribeAll = new Subject();
    this.demo = environment.demo
  }

  ngOnInit(): void {
    this.userService.preStepChangeData.pipe(takeUntil(this.unsubscribeAll)).subscribe(data => {
      if (data) {
        this.stepNo = data
      }
    })
    this.yearList = []
    let fullYear = (new Date()).getFullYear()
    let i = 0
    for (i = 0; i <= 20; i++) {
      this.yearList.push(fullYear + i)
    }
    if (window.localStorage["pricingModel"]) {
      this.applyCode = window.localStorage["pricingModel"]
      if (this.applyCode) {
        this.validCode = true
      }
    }
    this.getActivePlan()
  }
  activatePlan(id) {

  }

  getActivePlan() {
    let inputJson = {
      "demo": this.demo
    }
    this.userService.activePlan(inputJson).subscribe(data => {
      this.activePlanId = ""
      this.allPlan = data.result
      let activePlan = data.result.find(v => parseFloat(v.membership_price) == 199)
      if (activePlan) {
        this.activePlanId = activePlan.stripe_plan_id
      }
    }, err => {
      this.toastr.error(err)
    })
  }

  codeVerify() {
    if (this.applyCode && this.applyCode.length > 3) {
      let inputJson = {
        "coupon": this.applyCode,
        "planId": this.activePlanId,
        "demo": this.demo
      }
      this.userService.codeChecking(inputJson).subscribe(data => {
        this.validCode = true
        this.changeDetector.detectChanges()
      }, err => {
        this.validCode = false
        this.changeDetector.detectChanges()
      })
    }
  }

  openModal(addCard: TemplateRef<any>) {
    let config = {
      ignoreBackdropClick: true,
      class: 'custom-modal addFund modal-dialog-centered'
    };
    this.modalRef = this.modalService.show(addCard, config);
  }
  selectMonth(item) {
    this.SelectedMonth = item;
  }
  selectYear(item) {
    this.selectedYear = item;
  }

submitLevel(){
  this.serverBusy = true
  this.userService.saveLevelTwo().subscribe(data=>{
    window.localStorage['pricingModel'] = this.applyCode;
    this.userService.onNextSteoData(2,'next')
    this.serverBusy = false
    this.toastr.success(data.message)
  },err=>{
    this.serverBusy = false
    this.toastr.error(err)
  })
}

  preStep() {
    this.userService.onNextSteoData(2, 'pre')
  }

  redirectToNext() {
    this.userService.onNextSteoData(2, 'next')
  }

  setPlan(item) {
    if (item == 10) {
      this.plan = 'basic';
      let activePlan = this.allPlan.find(v => v.id == item)
      if (activePlan) {
        this.activePlanId = activePlan.stripe_plan_id
      }
      this.enable_virtual = 0
    } else {
      this.plan = 'pro';
      let activePlan = this.allPlan.find(v => v.id == item)
      if (activePlan) {
        this.activePlanId = activePlan.stripe_plan_id
      }
      this.enable_virtual = 1;

    }
    this.changeDetector.detectChanges()
  }
}
