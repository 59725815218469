export class basicInfo {
    "first_name" : string = "";
    "last_name" : string = "";
    "email" : string = "";
    "password" : string = "";
    "phone_number" : any = "";
    "next_step" : 1;
    "timezone" : string = "";
    "captcha" : string = "";
}

export class addStaff {
    "first_name" : string = "";
    "last_name": string = "";
    "email": string = "";
    "phone": any = "";
    "profileFile" : any = "";
    "profile_pic": any = "../../../../assets/images/avatar-placeholder.png";
}

export class addWidget {
    "enable_widget" : boolean = false;
    "widget_name" : string = "";
    "website_name" : string = "";
    "site_url" : string = "";
    "user_id" : string = "";
    "user_title" : string = "";
    "assign_user_phone" : any = "";
    "google_analytics_code" : string = "";
    "auto_open" : boolean = false;
    "auto_open_mobile" : boolean = false;
    "google_review_mobile_hide" : boolean = false;
    "enable_staff_sms" : boolean = false;
    "google_review_enable" : boolean = false;
    "review_show_interval" : string = "";
    "business_id" : any = [];
    "staff_name" : string = "";
}

export class widgetVariable {
    markers : Array<any> = []
    fullPlaceData : Array<any> = []
    stateList : Array<any> = []
    cityList : Array<any> = []
}

export class addBusiness {
  name : string = "";
  website : string = "";
  email : string = "";    
  logo : string = "";    
  phone : any = "";    
  address : string = "";    
  city : string = "";    
  city_name: string = "";
  state : string = "";
  state_name: string = ""; 
  zip : string = "";    
  place_id : string = "";    
  lat : string = "";    
  lng : string = "";
}