<div class="pt-4 pb-4">
    <!-- <p class="text-center staffImage">
        <img src="../../../../assets/images/Create_staff.svg" />
    </p> -->
    <h2 class="reg-heading text-left">Create Staff</h2>

    <div class="profile-box ">
        <!-- show this part on add or update situation -->
        <div class="white-box shadow-none">

            <!-- header -->
            <div class="card profile-body border-0 rounded-0 p-3 pb-2">
                <form #addStaffForm="ngForm" name="addStaffForm">
                    <div class="card-body">
                        <div class="media mb-4 profile-image-sec">
                            <!-- left side -->
                            <div class="staff-member-left">
                                <figure class="align-self-center rounded-circle m-0 profile-upload position-relative">
                                    <img class="rounded-circle" [src]="addStaffModel.profile_pic" alt="user image" />
                                    <label
                                        class="image-upload rounded-circle m-0 position-absolute d-flex justify-content-center align-items-center">
                                        <input class="position-absolute" type="file" accept="image/*" #fileInput
                                            (change)="profilePic($event)" />
                                        <i class="material-icons">
                                            camera
                                        </i>
                                    </label>
                                    <!-- End -->
                                </figure>
                                <button class="btn btn-link remove-profile-img">Remove Image</button>
                            </div>
                            <!-- end -->
                            <!-- right panel -->
                            <div class="media-body align-self-center pl-sm-3  pl-md-4">
                                <h6 class="m-0 font-weight-bold"> {{addStaffModel.first_name}} {{addStaffModel.last_name}}</h6>
                                <p class="mb-0 mt-1 f-w-500 text-muted" *ngIf="addStaffModel.email"><i class="material-icons">
                                        mail
                                    </i> &nbsp; {{addStaffModel.email}}
                                </p>
                            </div>
                            <!-- end -->
                        </div>
                        <!-- form filend -->
                        <div class="row">
                            <div class="col-lg-10 col-md-12 col-xl-11">
                                <div class="row">
                                    <div class="col-sm-6 col-md-12 col-lg-6">
                                        <div class="form-group widget-form-group icon-field">
                                            <label for="">First Name<span class="text-danger">*</span>

                                            </label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" pattern="[a-zA-Z]+" maxlength="25" #firstNameModel="ngModel" name="first_name" [ngClass]="{'is-invalid': firstNameModel.invalid && (firstNameModel.dirty || firstNameModel.touched)}" required [(ngModel)]="addStaffModel.first_name"/>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="material-icons">
                                                            person
                                                        </i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div *ngIf="firstNameModel.invalid && (firstNameModel.dirty || firstNameModel.touched)"
                                            class="invalid-feedback d-block">
                                            <div *ngIf="firstNameModel.errors.required">
                                               First name is required.
                                            </div>
                                            <div *ngIf="firstNameModel.errors.pattern">
                                                Please enter character only.
                                            </div>
                                         </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-12 col-lg-6">
                                        <div class="form-group widget-form-group icon-field">
                                            <label for="">Last Name
                                            </label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"  pattern="[a-zA-Z]+" maxlength="25"  #lastNameModel="ngModel" [ngClass]="{'is-invalid': lastNameModel.invalid && (lastNameModel.dirty || lastNameModel.touched)}" name="last_name" [(ngModel)]="addStaffModel.last_name"/>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="material-icons">
                                                            person
                                                        </i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div *ngIf="lastNameModel.invalid && (lastNameModel.dirty || lastNameModel.touched)"
                                            class="invalid-feedback d-block">
                                            <div *ngIf="lastNameModel.errors.required">
                                               Last name is required.
                                            </div>
                                            <div *ngIf="lastNameModel.errors.pattern">
                                                Please enter character only.
                                            </div>
                                         </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-12 col-lg-6">
                                        <div class="form-group widget-form-group icon-field">
                                            <label for="">E-mail<span class="text-danger">*</span>

                                            </label>
                                            <div class="input-group">
                                                <input type="text" required (focusout)="availablityCheck()" class="form-control" pattern="^[A-Za-z0-9._-]+@[A-Za-z0-9._-]+\.[A-Za-z]{2,4}$" #emailModel="ngModel" [ngClass]="{'is-invalid': emailModel.invalid && (emailModel.dirty || emailModel.touched)}" name="emailAddress" [(ngModel)]="addStaffModel.email"/>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="material-icons">
                                                            email
                                                        </i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div *ngIf="emailModel.invalid && (emailModel.dirty || emailModel.touched)"
                                            class="invalid-feedback d-block">
                                            <div *ngIf="emailModel.errors.required">
                                               Email is required.
                                            </div>
                                            <div *ngIf="emailModel.errors.pattern">
                                               Please enter valid email.
                                            </div>
                                         </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-12 col-lg-6">
                                        <div class="form-group widget-form-group icon-field">
                                            <label for="">Phone Number</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" minlength="10" appPhoneMask maxlength="15" #phoneModel="ngModel" name="phone" required [(ngModel)]="addStaffModel.phone" [ngClass]="{'is-invalid': phoneModel.invalid && (phoneModel.dirty || phoneModel.touched)}"/>
                                                <div class="input-group-prepend">
                                                    <span class="std-code">
                                                        <img src="../../../../../assets/images/usa-country.png" />
                                                        +1
                                                        <span class="material-icons dropdown-icon">
                                                            arrow_drop_down
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div *ngIf="phoneModel.invalid && (phoneModel.dirty || phoneModel.touched)"
                                            class="invalid-feedback d-block">
                                            <div *ngIf="phoneModel.errors.required">
                                               Phone number is required.
                                            </div>
                                            <div *ngIf="phoneModel.errors.pattern">
                                               Please enter number only.
                                            </div>
                                            <div *ngIf="phoneModel.errors.minlength">
                                              Please enter valid phone number.
                                           </div>
                                         </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- end -->
                    </div>
                </form>
            </div>
        </div>

    </div>


    <hr class="thereHr mt-4" />
    <div class="clearfix">
        <button type="button" class="btn submit-btn mt-2 small previous-btn float-left" (click)="preStep()">
            Previous
        </button>

        <button type="submit" *ngIf="stepNo<4" class="btn btn-primary submit-btn mt-2 small theme-btn-shadow float-right" (click)="saveStaff()" [disabled]="addStaffForm.invalid">
            Next
        </button>
        <button type="submit" (click)="redirectToNext()" *ngIf="stepNo>=4" class="btn btn-primary submit-btn mt-2 small theme-btn-shadow float-right">
            Next
         </button>
        <button class="btn btn-link text-primary btn-sm float-right skip-btn mt-2 mr-2 f-w-500" (click)="skip()">Skip</button>
    </div>

</div>
<div class="loaderDiv" *ngIf="serverBusy">
    <div class="loader-div">
        <div class="circle circle-1"></div>
        <div class="circle circle-2"></div>
    </div>
</div>