<header>
   <div class="container-fluid">
      <div class="row align-items-center">
         <div class="col-3">
            <a href="javascript.void(0)" class="logo-section">
               <img src="../../../assets/images/logo.png" alt="logo" />
            </a>
         </div>
         <div class="col-9">
            <div class="header-right text-right">
               <div class="user-panel-wraper dropdown d-inline-block" dropdown placement="bottom right">
                  <button class="user-panel" dropdownToggle>
                     <figure class="user-image">
                        <img [src]="userDetail.profile_pic" alt="user Name" />
                     </figure>
                     <span class="user-name">{{userDetail.full_name}}</span>
                  </button>
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                     aria-labelledby="button-basic">
                     <li (click)="regirectTo('/setting/profile')" role="menuitem"><a class="dropdown-item f-w-500">
                           <!-- <i class="fa fa-user" aria-hidden="true"></i> -->
                           <i class="material-icons">
                              person
                           </i>
                           Profile</a>
                     </li>
                     <li role="menuitem" (click)="regirectTo('/logout')"><a class="dropdown-item f-w-500"><i
                              class="fa fa-sign-out" aria-hidden="true"></i> log Out</a></li>
                  </ul>
               </div>
               <button class="only-lg trpnsparent-btn open-menu-btn ml-3" onclick="openMenu()">
                  <span class="material-icons">
                     menu
                  </span>
               </button>
            </div>
         </div>
      </div>
   </div>
</header>
<!-- Header navigation -->
<div class="menu-overlay" onclick="closeMenu()" id="menu-overlay"></div>
<nav class="avbar navbar-expand-lg  main-nav" id="main-menu">
   <button class="only-lg trpnsparent-btn close close-menu" onclick="closeMenu()">
      <span class="material-icons">
         close
      </span>
   </button>
   <ul class="navbar-nav" id="myDIV">
      <!-- Dropdown -->
      <li class="nav-item">
         <a class="nav-link f-w-500 no-icon" [ngClass]="{'active': activeMainMenu==='inbox'}">
            <!-- <i class="fa fa-commenting-o" aria-hidden="true"></i>  -->
            Inbox
         </a>
         <!-- add hightlight class to show chat count   &&&& add active class to show active effect -->
         <button type="button" class="btn btn-link submenu-btn highlight" tooltip="Widget"  placement="bottom" (click)="chatType('widget')"
            onclick="closeMenu()">
            <span class="badge badge-primary badge-theme ml-2 glowing"
               *ngIf="chatCount.widgetCount">{{chatCount.widgetCount}}</span>
            <!-- add this part is only for showing -->
            <span class="material-icons">comment</span>
         </button>
         <button type="button" class="btn btn-link submenu-btn" tooltip="Form Fills" placement="bottom" (click)="chatType('form')"
            onclick="closeMenu()">
            <span class="badge badge-primary badge-theme ml-2 theme-glowing"
               *ngIf="chatCount.formCount">{{chatCount.formCount}}</span>
            <span class="material-icons">description</span></button>
         <button type="button" class="btn btn-link submenu-btn" tooltip="Campaign Messages"  placement="bottom" (click)="chatType('other')">
            <span class="badge badge-primary badge-theme ml-2 theme-glowing"
               *ngIf="chatCount.otherCount">{{chatCount.otherCount}}</span>
            <span class="material-icons">
               layers
            </span></button>
      </li>
      <li class="nav-item" *ngIf="enable_virtual">
         <button type="button" class="btn btn-link submenu-btn highlight" tooltip="Virtual Consult"  placement="bottom"
            (click)="checkCCExpiry('vrchat',null,null)">
            <!-- <span class="badge badge-primary badge-theme ml-2" *ngIf="chatCount.widgetCount">{{chatCount.widgetCount}}</span> -->
            <!-- add this part is only for showing -->
            <span class="badge badge-primary badge-theme ml-2 glowing"
               *ngIf="chatCount.vrCount">{{chatCount.vrCount}}</span>
            <span class="material-icons">comment</span>
         </button>
         <a class="nav-link f-w-500 no-icon" [ngClass]="{'active': activeMainMenu==='virtualConsult'}" (click)="checkCCExpiry('vrchat',null,null)" onclick="closeMenu()">
            Virtual Consult
         </a>
      </li>
      <li class="nav-item" *ngIf="enable_virtual">
         <button type="button" class="btn btn-link submenu-btn highlight" (click)="checkCCExpiry('vwrchat',null,null)">

            <span class="badge badge-primary badge-theme ml-2 glowing"
               *ngIf="chatCount.virtualwaitCount">{{chatCount.virtualwaitCount}}</span>
            <span class="material-icons">comment</span>
         </button>
         <a class="nav-link f-w-500  no-icon" [ngClass]="{'active': activeMainMenu==='virtualWaitingRoom'}" (click)="checkCCExpiry('vwrchat',null,null)" onclick="closeMenu()">
            Virtual Waiting Room
         </a>
      </li>
      <li class="nav-item dropdown" dropdown>
         <a class="nav-link f-w-500" (click)="checkCase(null)"  dropdownToggle [ngClass]="{'active': activeMainMenu==='campaign'}">
            <i class="fa fa-hashtag highlight" aria-hidden="true"></i>
            Campaign</a>
            <div [hidden]="showCheck" id="" *dropdownMenu class="setting-dropdown-menu dropdown-menu" role="menu"
               aria-labelledby="button-basic">
               <div class="card border-0">


                  <!-- Channels -->
                  <div class="card-body pt-2 pb-2 border-0">
                     <div class="media">
                        <figure class="mb-0 mr-3">
                           <i class="material-icons">
                              people
                           </i>
                        </figure>
                        <div class="media-body">
                           <h6 class="mb-0 font-weight-bold mt-1">Manage Group</h6>
                           <div class="pt-2 pb-2">
                              <a type="button" class="btn btn-outline-secondary btn-sm f-w-500"  [ngClass]="{'active': activeSubMenuClass==='group'}" (click)="checkCCExpiry('/campaign/group','campaign','group')"
                                 onclick="closeMenu()">Group</a>
                              <a type="button" class="btn btn-outline-secondary btn-sm f-w-500" [ngClass]="{'active': activeSubMenuClass==='sendMessage'}" (click)="checkCCExpiry('/campaign/sendMessage','campaign','sendMessage')"
                                 onclick="closeMenu()">Send Message</a>
                              <a type="button" class="btn btn-outline-secondary btn-sm f-w-500" [ngClass]="{'active': activeSubMenuClass==='dndContact'}" (click)="checkCCExpiry('/campaign/dndContact','campaign','dndContact')"
                                 onclick="closeMenu()">DND Contacts</a>
                              <a type="button" class="btn btn-outline-secondary btn-sm f-w-500" [ngClass]="{'active': activeSubMenuClass==='allContact'}" (click)="checkCCExpiry('/campaign/allContact','campaign','allContact')"
                                 onclick="closeMenu()">All Contacts</a>
                              <a type="button" class="btn btn-outline-secondary btn-sm f-w-500" [ngClass]="{'active': activeSubMenuClass==='allCampaign'}" (click)="checkCCExpiry('/campaign/allCampaign','campaign','allCampaign')"
                                 onclick="closeMenu()">All Campaign</a>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
      </li>
      <li class="nav-item dropdown" dropdown>
         <a class="nav-link f-w-500" [ngClass]="{'active': activeMainMenu==='reviews'}" dropdownToggle><i class="fa fa-star-o highlight"
               aria-hidden="true"></i>
            Reviews</a>
         <div id="" *dropdownMenu class="setting-dropdown-menu dropdown-menu" role="menu"
            aria-labelledby="button-basic">
            <div class="card border-0">


               <!-- Channels -->
               <div class="card-body pt-2 pb-2 border-0">
                  <div class="media">
                     <figure class="mb-0 mr-3">
                        <i class="material-icons">
                           star_border
                        </i>
                     </figure>
                     <div class="media-body">
                        <h6 class="mb-0 font-weight-bold mt-1">Reviews</h6>
                        <div class="pt-2 pb-2">
                           <a type="button" [ngClass]="{'active': activeSubMenuClass==='sendReview'}" (click)="checkCCExpiry('/reviews/sendReview','reviews','sendReview')"
                              class="btn btn-outline-secondary btn-sm f-w-500" onclick="closeMenu()">Send Review</a>
                           <a type="button" [ngClass]="{'active': activeSubMenuClass==='location'}" (click)="checkCCExpiry('/reviews/location','reviews','location')"
                              class="btn btn-outline-secondary btn-sm f-w-500" onclick="closeMenu()">Locations</a>
                           <a type="button" [ngClass]="{'active': activeSubMenuClass==='userlist'}" (click)="checkCCExpiry('/reviews/user-list','reviews','userlist')"
                              class="btn btn-outline-secondary btn-sm f-w-500" onclick="closeMenu()">Previously Sent Reviews</a>
                           <a type="button" [ngClass]="{'active': activeSubMenuClass==='reviewmessage'}" (click)="checkCCExpiry('/reviews/review-message','reviews','reviewmessage')"
                              class="btn btn-outline-secondary btn-sm f-w-500" onclick="closeMenu()">Review Message</a>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </li>
      <li class="nav-item dropdown" dropdown>
         <a class="nav-link f-w-500"  dropdownToggle [ngClass]="{'active': activeMainMenu==='setting'}"><i class="fa fa-cog highlight"
               aria-hidden="true"></i>Settings</a>
               <i class="material-icons menu-info" container="body" tooltip="Adjust settings for all features" placement="bottom"
               containerClass="tooltip-md">
               info
            </i>
         <div id="" *dropdownMenu class="setting-dropdown-menu dropdown-menu" role="menu"
            aria-labelledby="button-basic">
            <div class="card border-0">

               <!-- organisation -->
               <div class="card-body pt-2 pb-2 border-top-0 border-right-0 border-left-0 border">
                  <div class="media">
                     <figure class="mb-0 mr-3">
                        <i class="material-icons">
                           people
                        </i>
                     </figure>
                     <div class="media-body">
                        <h6 class="mb-0 font-weight-bold mt-1">Organization</h6>
                        <div class="pt-2 pb-2" >
                           <a type="button" class="btn btn-outline-secondary btn-sm f-w-500"
                              [ngClass]="{'active': activeSubMenuClass==='staffMember'}" (click)="checkCCExpiry('/setting/staffMember','setting','staffMember')" onclick="closeMenu()">Staff Members</a>
                              <i class="material-icons menu-info" container="body" tooltip="Add edit and remove staff" placement="bottom"
                              containerClass="tooltip-md">
                              info
                           </i>
                        </div>

                     </div>
                  </div>
               </div>

               <!-- Channels -->
               <div class="card-body pt-2 pb-2 border-top-0 border-right-0 border-left-0 border">
                  <div class="media">
                     <figure class="mb-0 mr-3">
                        <i class="material-icons">
                           markunread
                        </i>
                     </figure>
                     <div class="media-body">
                        <h6 class="mb-0 font-weight-bold mt-1">Numbers</h6>
                        <div class="pt-2 pb-2">
                           <a type="button" class="btn btn-outline-secondary btn-sm f-w-500"
                              onclick="closeMenu()" [ngClass]="{'active': activeSubMenuClass==='sms'}" (click)="checkCCExpiry('/setting/sms','setting','sms')">Manage Number</a>
                              <i class="material-icons menu-info" container="body" tooltip="Add or remove phone number for chat raven features" placement="bottom"
                              containerClass="tooltip-md">
                              info
                           </i>
                        </div>

                     </div>
                  </div>
               </div>

               <!-- Settings -->
               <div class="card-body pt-2 pb-2 border-top-0 border-right-0 border-left-0 border">
                  <div class="media">
                     <figure class="mb-0 mr-3">
                        <i class="material-icons">
                           settings
                        </i>
                     </figure>
                     <div class="media-body">
                        <h6 class="mb-0 font-weight-bold mt-1">Settings</h6>
                        <div class="pt-2 pb-2">
                           <a type="button" class="btn btn-outline-secondary btn-sm f-w-500" [ngClass]="{'active': activeSubMenuClass==='widget'}" (click)="checkCCExpiry('/setting/widget','setting','widget')"
                           onclick="closeMenu()">Chat Widget</a>
                           <a *ngIf="enable_virtual" type="button" class="btn btn-outline-secondary btn-sm f-w-500" [ngClass]="{'active': activeSubMenuClass==='virtualConsultEnable'}" (click)="checkCCExpiry('/setting/virtualConsultEnable','setting','virtualConsultEnable')"
                              onclick="closeMenu()">Virtual Consult</a>
                           <a *ngIf="enable_virtual" type="button" class="btn btn-outline-secondary btn-sm f-w-500" [ngClass]="{'active': activeSubMenuClass==='virtualWaitRoomEnable'}" (click)="checkCCExpiry('/setting/virtualWaitRoomEnable','setting','virtualWaitRoomEnable')"
                              onclick="closeMenu()">Virtual Waiting
                              Room</a>
                           <!-- <a type="button" class="btn btn-outline-secondary btn-sm f-w-500" [ngClass]="{'active': activeSubMenuClass==='membershipHead'}" (click)="checkCCExpiry('/setting/membership','setting','membershipHead')"
                              onclick="closeMenu()">Membership</a> -->
                           <!-- <a type="button" class="btn btn-outline-secondary btn-sm f-w-500" (click)="activeClass('setting',null)"
                              onclick="closeMenu()">Cancel Membership</a> -->
                        </div>

                     </div>
                  </div>
               </div>

               <!-- wallet -->
               <div class="card-body pt-2 pb-2 border-0">
                  <div class="media">
                     <figure class="mb-0 mr-3">
                        <i class="material-icons">
                           account_balance_wallet
                        </i>
                     </figure>
                     <div class="media-body">
                        <h6 class="mb-0 font-weight-bold">Wallet <span
                              class="badge badge-pill badge-success wallet-amount headerAmount">
                              <h6 class="mt-1 mb-1">(${{amount}})</h6>
                           </span></h6>
                        <div class="pt-2 pb-2">
                           <a type="button" [ngClass]="{'active': activeSubMenuClass==='payment'}" (click)="checkCCExpiry('/setting/payment','setting','payment')"
                              class="btn btn-outline-secondary btn-sm f-w-500" onclick="closeMenu()">Payment</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </li>
      <li class="nav-item nav-itemss">
         <a class="nav-link f-w-500" [ngClass]="{'active': activeMainMenu==='quickMessage'}"  (click)="checkCCExpiry('/message/quickMessage','quickMessage',null)" onclick="closeMenu()">
            <i class="fa fa-comments-o highlight" aria-hidden="true"></i>Quick
            Message  </a>
            <i class="material-icons menu-info" container="body" tooltip="Send a text message to a contact not already in your list" placement="bottom"
               containerClass="tooltip-md">
               info
            </i> 
      </li>
   </ul>
</nav>

<ng-template #addCardPopup>
   <div class="modal-header pt-2 pb-2 pr-3 pl-3 align-items-center">
      <h4 class="modal-title">Add New Credit Card</h4>
      <button type="button" aria-label="Close" class="btn btn-primary cancel-btn" (click)="closeDiv()">
         <span class="material-icons"> close </span></button>
   </div>
   <div class="modal-body p-3 addCardModalBody">
      <form name="addCard" #addCardForm="ngForm">
         <div class="add-card-block p-4">
            <div class="d-flex align-items-center justify-content-between mb-4">
               <div>
                  <img src="../../../assets/images/chip-image.png" />
               </div>
               <div>
                  <h2 class="card-heading">Credit Card</h2>
               </div>
            </div>
            <div class="form-group widget-form-group">
               <label for="">Card Number
                  <a  tooltip="Card Number" placement="bottom">
                     <i class="material-icons">
                        info
                     </i>
                  </a>
                  <i class="text-danger">*</i>
               </label>
               <input type="text" [(ngModel)]="addCardModel.card_no" #cardNumberModel="ngModel" class="form-control" placeholder="Card Number" required pattern="[0-9]+"
                  name="card_no" minlength="16" pattern="[0-9]+" maxlength="16" [ngClass]="{'is-invalid': cardNumberModel.invalid && (cardNumberModel.dirty || cardNumberModel.touched)}"/>
              <div *ngIf="cardNumberModel.invalid && (cardNumberModel.dirty || cardNumberModel.touched)"
                  class="invalid-feedback d-block">
                  <div *ngIf="cardNumberModel.errors.required">
                     Card number is required.
                  </div>
                  <div *ngIf="cardNumberModel.errors.pattern">
                     Please enter number only.
                  </div>
                  <div *ngIf="cardNumberModel.errors.minlength">
                    Please enter valid card number.
                 </div>
               </div>
            </div>
            <div class="form-group widget-form-group">
               <label for="">Name on Card
                  <a  tooltip="Name on Card" placement="bottom">
                     <i class="material-icons">
                        info
                     </i>
                  </a>
                  <i class="text-danger">*</i>
               </label>
               <input type="text" #nameModel="ngModel" pattern="[a-zA-Z ]+" maxlength="25" class="form-control" [(ngModel)]="addCardModel.name_on_card" [ngClass]="{'is-invalid': nameModel.invalid && (nameModel.dirty || nameModel.touched)}" placeholder="Name on Card" name="nameOnCard" required/>
               <div *ngIf="nameModel.invalid && (nameModel.dirty || nameModel.touched)"
               class="invalid-feedback d-block">
               <div *ngIf="nameModel.errors.required">
                  Name on card is required.
               </div>
               <div *ngIf="nameModel.errors.pattern">
                  Please enter character only.
               </div>
              </div>
            </div>
            <div class="row justify-content-between">
               <div class="col-auto">
                  <div class="form-group widget-form-group">
                     <label for="" class="d-block">EXP Date
                        <a  tooltip="EXP Date" placement="bottom">
                           <i class="material-icons">
                              info
                           </i>
                        </a>
                        <i class="text-danger">*</i>
                     </label>
                     <div class="btn-group d-inline-flex w-100px" dropdown [dropup]="true">
                        <div aria-controls="dropdown-animated" dropdownToggle
                           class="input-group custom-select-value form-control">
                           {{SelectedMonth }}</div>
                        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu"
                           aria-labelledby="button-animated" required>
                           <li role="menuitem" *ngFor="let item of monthList">
                              <a class="dropdown-item"
                                  (click)="selectMonth(item.id)">{{item.name}}</a>
                           </li>
                        </ul>
                     </div>

                     <div class="btn-group d-inline-flex ml-2 w-100px" dropdown [dropup]="true">
                        <div aria-controls="dropdown-animated" dropdownToggle
                           class="input-group custom-select-value form-control">
                           {{selectedYear}}</div>
                        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu"
                           aria-labelledby="button-animated">
                           <li role="menuitem" *ngFor="let item of yearList">
                              <a class="dropdown-item"  (click)="selectYear(item)">{{item}}</a>
                           </li>
                        </ul>
                     </div>

                  </div>
               </div>

               <div class="col-auto">
                  <div class="form-group widget-form-group">
                     <label for="">CVV No.
                        <a  tooltip="CVV No" placement="bottom">
                           <i class="material-icons">
                              info
                           </i>
                        </a>
                        <i class="text-danger">*</i>
                     </label>
                     <input type="password" #passwordModel="ngModel" maxlength="5" minlength="3" [ngClass]="{'is-invalid': passwordModel.invalid && (passwordModel.dirty || passwordModel.touched)}" class="form-control cvv-field" placeholder="###"
                        required name="cvvNumber" [(ngModel)]="addCardModel.cvvNumber" pattern="[0-9]+"/>
                      <div *ngIf="passwordModel.invalid && (passwordModel.dirty || passwordModel.touched)"
                        class="invalid-feedback d-block">
                        <div *ngIf="passwordModel.errors.required">
                           Cvv number is required.
                        </div>
                        <div *ngIf="passwordModel.errors.pattern">
                           Please enter number only.
                        </div>
                        <div *ngIf="passwordModel.errors.minlength">
                          Please enter valid number only.
                       </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="d-flex justify-content-center mt-3">
            <button class="btn btn-primary submit-btn  small" (click)="submitLevel()" [disabled]="addCardForm.invalid || submitClick">
               <i class="material-icons"> done </i>
               Submit Details
            </button>
         </div>
      </form>
   </div>
</ng-template>
<ng-template #memberShip>
   <div class="modal-header pt-2 pb-2 pr-3 pl-3 align-items-center">
      <h4 class="modal-title" >Join Membership</h4>
      <button *ngIf="!profileMode && trialMode" type="button" aria-label="Close" class="btn btn-primary cancel-btn" (click)="cancelMembership()">
         <span class="material-icons"> close </span></button>
   </div>
   <div class="modal-body p-3 addCardModalBody">
      <div class="c-img-outer">
         <img src="../../../assets/images/calendar.png"/>
         <h3 class="day-count">
            {{noOfDaysLeft}}
         </h3>
         <p>DAYS REMAINING</p>
      </div>
      
      <p class="text-center"> You have {{noOfDaysLeft}} days remaining on your free trial period. Subscribe to ensure you don't miss any website leads when your trial period runs out. </p>
      <div class="d-flex justify-content-center mt-3">
         <button class="btn btn-primary submit-btn small m-2 theme-btn-shadow " (click)="subscribePlan()">
            <i class="material-icons"> done_all </i>
            Subscribe
         </button>
         <button class="btn delete-confirm-btn cancel-btn small m-2 danger-btn-shadow" (click)="unSubscribePlan()">
            <i class="material-icons"> close </i>
            Close Account
         </button>
      </div>
   </div>
</ng-template>
<div *ngIf="serverBusy" class="loaderDiv">
   <div class="loader-div">
      <div class="circle circle-1"></div>
      <div class="circle circle-2"></div>
   </div>
</div>