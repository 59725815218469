import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommanApiService {


  constructor(private apiService: ApiService) { }

  getCount() {
    return this.apiService.get('/chat/myChatCounts')
  }
  revokMembership() {
    return this.apiService.get('/user/revokeTrail')
  }
  cancelMembership() {
    return this.apiService.get('/user/cancleMembershipImidiately')
  }

  closeAccount() {
    return this.apiService.get('/user/closeAccountImidiately')
  }

  saveFireBaseToken(data) {
    return this.apiService.post('/user/updateFirebaseTokenByUserId', data)
  }

  private statusUpdateSource: BehaviorSubject<any> = new BehaviorSubject(null);
  statusUpdateData = this.statusUpdateSource.asObservable();
  onStatusUpdate(data: any) {
    this.statusUpdateSource.next(data);
  }

  private activeMenuSource: BehaviorSubject<any> = new BehaviorSubject(null);
  activeMenuData = this.activeMenuSource.asObservable();
  onActiveMenuUpdate(data: any) {
    this.activeMenuSource.next(data);
  }

  private chatMessageReceivedSource: BehaviorSubject<any> = new BehaviorSubject(null);
  chatMessageReceived = this.chatMessageReceivedSource.asObservable();
  OnChatReceived(data: any) {
    this.chatMessageReceivedSource.next(data);
  }

  private chatReadSource: BehaviorSubject<any> = new BehaviorSubject(null);
  readData = this.chatReadSource.asObservable();
  OnCountUpdate(data: any) {
    this.chatReadSource.next(data);
  }

  private newWidgetSource: BehaviorSubject<any> = new BehaviorSubject(null);
  widgetCount = this.newWidgetSource.asObservable();
  updateWidgetCount(data: any) {
    this.newWidgetSource.next(data);
  }

  private updateFundSource: BehaviorSubject<any> = new BehaviorSubject(null);
  updateFundData = this.updateFundSource.asObservable();

  updateFund(data: any) {
    this.updateFundSource.next(data);
  }

  getNoti(data) {
    return this.apiService.post('/chat/getMyNoti', data)
  }

  getStatusVr() {
    return this.apiService.get('/virtual/getVirtualProgram')
  }

  addCardNew(data) {
    return this.apiService.post('/user/addCreditCard', data)
  }

  userDetailData() {
    return this.apiService.get('/user/detail')
  }

  getccExpiry() {
    return this.apiService.get('/user/CheckStatus')
  }

  getcamp() {
    return this.apiService.get('/user/campaignNo')
  }

  activeTabList() {
    let obj = [{
      "route": "virtual",
      "id": "virtualConsult",
      "subMenu": []
    }, {
      "route": "waitingroom",
      "id": "virtualWaitingRoom",
      "subMenu": []
    }, {
      "route": "campaign",
      "id": "campaign",
      "subMenu": [{
        "id": "group",
        "route": "group"
      }, {
        "id": "sendMessage",
        "route": "sendMessage"
      }, {
        "id": "dndContact",
        "route": "dndContact"
      }, {
        "id": "allContact",
        "route": "allContact"
      }, {
        "id": "allCampaign",
        "route": "allCampaign"
      }]
    }, {
      "route": "reviews",
      "id": "reviews",
      "subMenu": [{
        "id": "sendReview",
        "route": "sendReview"
      }, {
        "id": "location",
        "route": "location"
      }, {
        "id": "userlist",
        "route": "user-list"
      }, {
        "id": "reviewmessage",
        "route": "review-message"
      }]
    }, {
      "route": "setting",
      "id": "setting",
      "subMenu": [{
        "id": "staffMember",
        "route": "staffMember"
      }, {
        "id": "sms",
        "route": "sms"
      }, {
        "id": "widget",
        "route": "widget"
      }, {
        "id": "virtualConsultEnable",
        "route": "virtualConsultEnable"
      }, {
        "id": "virtualWaitRoomEnable",
        "route": "virtualWaitRoomEnable"
      }, {
        "id": "membershipHead",
        "route": "membership"
      }, {
        "id": "payment",
        "route": "payment"
      }]
    }, {
      "route": "quickMessage",
      "id": "quickMessage",
      "subMenu": []
    }, {
      "route": "chat",
      "id": "inbox",
      "subMenu": []
    }]
    let val = {
      "route": "chat",
      "id": "inbox",
      "subMenu": []
    }
    let url = window.location.href
    let searchVal = false
    obj.forEach(v => {
      let searchData = ((url).toLowerCase()).search((v.route).toLowerCase())
      if (searchData > 0 && !searchVal) {
        searchVal = true
        val = v
      }
    })
    let subMenuVal = ""
    if (val.subMenu.length > 0) {
      let url = window.location.href
      val.subMenu.forEach(v => {
        let searchData = ((url).toLowerCase()).search((v.route).toLowerCase())
        if (searchData > 0) {
          subMenuVal = v.id
        }
      })
    }
    let inputData = {
      mainMenu: val.id,
      subMenu: subMenuVal
    }
    return inputData
  }

  monthListVal() {
    let obj = [{
      "id": "01",
      "name": "01"
    }, {
      "id": "02",
      "name": "02"
    }, {
      "id": "03",
      "name": "03"
    }, {
      "id": "04",
      "name": "04"
    }, {
      "id": "05",
      "name": "05"
    }, {
      "id": "06",
      "name": "06"
    }, {
      "id": "07",
      "name": "07"
    }, {
      "id": "08",
      "name": "08"
    }, {
      "id": "09",
      "name": "09"
    }, {
      "id": "10",
      "name": "10"
    }, {
      "id": "11",
      "name": "11"
    }, {
      "id": "12",
      "name": "12"
    }]
    return obj
  }

}
